import { createReducer } from 'deox';
import { setEditSaveState } from './save-edit';
import {
  saveEditAction,
  storeNameAction,
  toggleTourAction,
  validationCalcAction,
  validationCheckAction,
  cellActivateAction,
} from '@/redux/actions/interaction';
import { setValidationCalcState } from './validation-calc';
import { setValidationCheckState } from './validation-check';
import { setTourState } from './guided-tour';
import { setStoreNameState } from './store-name';
import { setCellActivateState } from './cell-activate';

const initialState: any = {
  validateCalcStatus: false,
  validateCheck: false,
  saveEditResponse: undefined,
  isTour: 'main',
  isStoreName: 'project-001-pile',
  CellActivate: undefined,
};

const interactionReducer = createReducer(initialState, (handleAction) => [
  handleAction(saveEditAction.request, (state, action) => {
    const updatedState = setEditSaveState(state, action);
    return updatedState;
  }),
  handleAction(validationCalcAction.request, (state, action) => {
    const updatedState = setValidationCalcState(state, action);
    return updatedState;
  }),
  handleAction(validationCheckAction.request, (state, action) => {
    const updatedState = setValidationCheckState(state, action);
    return updatedState;
  }),
  handleAction(toggleTourAction.request, (state, action) => {
    const updatedState = setTourState(state, action);
    return updatedState;
  }),
  handleAction(storeNameAction.request, (state, action) => {
    const updatedState = setStoreNameState(state, action);
    return updatedState;
  }),
  handleAction(cellActivateAction.request, (state, action) => {
    const updatedState = setCellActivateState(state, action);
    return updatedState;
  }),
]);

export default interactionReducer;
