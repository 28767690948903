import { createActionCreator } from 'deox';

// CONSTANTS

export enum EDisableDatagridAction {
  DISABLEDATAGRID = 'DISABLEDATAGRID',
  DISABLEDATAGRID_REQUEST = 'DISABLEDATAGRID_REQUEST',
  DISABLEDATAGRID_SUCCESS = 'DISABLEDATAGRID_SUCCESS',
  DISABLEDATAGRID_FAILED = 'DISABLEDATAGRID_FAILED',
}

// TYPES

export type TDisableDatagridRequest = {
  type: EDisableDatagridAction.DISABLEDATAGRID_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TDisableDatagridSuccess = {
  type: EDisableDatagridAction.DISABLEDATAGRID_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TDisableDatagridFailed = {
  type: EDisableDatagridAction.DISABLEDATAGRID_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const saveDisableDatagridAction = {
  request: createActionCreator(
    EDisableDatagridAction.DISABLEDATAGRID_REQUEST,
    (resolve) =>
      (payload: { status: boolean }): TDisableDatagridRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EDisableDatagridAction.DISABLEDATAGRID_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TDisableDatagridSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EDisableDatagridAction.DISABLEDATAGRID_FAILED,
    (resolve) => (/* Pass your payload data here */): TDisableDatagridFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
