import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetUserInfoParams = unknown;

export type TGetUserInfoResponse = {
  status_code: number;
  status_message: string;
  data: TUserInfo;
};

export type TUserInfo = {
  user_id: number;
  fullName: string;
  username: string;
  email: string;
  role: string;
  companyInfo: {
    company_id?: number;
    companyName?: string;
    companyLocation?: string;
    companyStreet?: string;
    companyPostalCode?: string;
    companyPhone?: string;
    companyFax?: string;
    companyEmail?: string;
    companyLogo?: string;
    company_activated?: number;
  };
  licenseStatus: string;
  expireDate: string;
  finalRole: string;
};

export const getUserInfo = async (): Promise<TUserInfo> => {
  try {
    const response = await ApiService.get(`/users/api/getUserInfo/`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
