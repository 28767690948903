import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TChangePasswordParams = unknown;

export type TChangePasswordMaterials = {
  dataSubmit?: any;
};

export type TChangePasswordResponse = TCommonResponse;

export const changePassword = async (dataSubmit: TChangePasswordMaterials): Promise<TChangePasswordResponse> => {
  const data = await dataSubmit;
  const response = await ApiService.post(`/users/api/changePassword/`, data);
  return response?.data;
};
