import { createActionCreator } from 'deox';

import { TPostCompanySettingMaterials, TPostCompanySettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// CONSTANTS

export enum EPostCompanySettingAction {
  POST_COMPANYSETTING = 'POST_COMPANYSETTING',
  POST_COMPANYSETTING_REQUEST = 'POST_COMPANYSETTING_REQUEST',
  POST_COMPANYSETTING_SUCCESS = 'POST_COMPANYSETTING_SUCCESS',
  POST_COMPANYSETTING_FAILED = 'POST_COMPANYSETTING_FAILED',
}

// TYPES

export type TPostCompanySettingRequest = {
  type: EPostCompanySettingAction.POST_COMPANYSETTING_REQUEST;
  payload: {
    materials: TPostCompanySettingMaterials;
    successCallback?: (response: TPostCompanySettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostCompanySettingSuccess = {
  type: EPostCompanySettingAction.POST_COMPANYSETTING_SUCCESS;
  payload: { response: TPostCompanySettingResponse };
};

export type TPostCompanySettingFailed = { type: EPostCompanySettingAction.POST_COMPANYSETTING_FAILED };

// FUNCTION

export const postCompanySettingAction = {
  request: createActionCreator(
    EPostCompanySettingAction.POST_COMPANYSETTING_REQUEST,
    (resolve) =>
      (
        materials: TPostCompanySettingMaterials,
        successCallback?: (response: TPostCompanySettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostCompanySettingRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostCompanySettingAction.POST_COMPANYSETTING_SUCCESS,
    (resolve) =>
      (response: TPostCompanySettingResponse): TPostCompanySettingSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostCompanySettingAction.POST_COMPANYSETTING_FAILED,
    (resolve) =>
      (error: any): TPostCompanySettingFailed => {
        message.error('SAVE_COMPANYDETAIL_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
