import { createReducer } from 'deox';
import { setGridSelectedState } from './grid-selected';
import { TGridSelectedRequest, gridSelectedAction } from '@/redux/actions/grid-selected';

export type TEditState = {
  GridSelected?: TGridSelectedRequest;
};

const initialState: TEditState = {
  GridSelected: undefined,
};

const GridSelectedReducer = createReducer(initialState, (handleAction) => [
  handleAction(gridSelectedAction.request, (state, action) => {
    const updatedState = setGridSelectedState(state, action);

    return updatedState;
  }),
]);

export default GridSelectedReducer;
