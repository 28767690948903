import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import language JSON files
import localEnTranslation from './locales/en-local.json';
import remoteEnTranslation from './locales/en-remote.json';
import localDeTranslation from './locales/de-local.json';
import remoteDeTranslation from './locales/de-remote.json';

// Merge language data
const mergedDataEn = {
  ...localEnTranslation,
  ...remoteEnTranslation,
};
const mergedDataDe = {
  ...localDeTranslation,
  ...remoteDeTranslation,
};

// Configure i18n
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['de', 'en'], // List of supported languages

    // Fallback language if the detected language is not supported
    fallbackLng: 'de',

    resources: {
      en: {
        translation: mergedDataEn, // Use the merged data for English
      },
      de: {
        translation: mergedDataDe, // Use the merged data for German
      },
      // Add more languages and translations as needed
    },

    // Detect the user's language
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'], // Store the language in localStorage for subsequent visits
    },

    // Debug mode (optional)
    debug: true,

    // Specify keySeparator and nsSeparator if needed
    // keySeparator: false,
    // nsSeparator: false,

    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
  });

// Example: Setting the selected language in local storage
const storedLanguage = localStorage.getItem('language')?.length > 0 ? localStorage.getItem('language') : 'de';

// Assuming you have a list of supported languages
const supportedLanguages = ['en', 'de']; // Add more languages as needed

// Check if the stored language is one of the supported languages
if (supportedLanguages.includes(storedLanguage)) {
  i18n.changeLanguage(storedLanguage);
} else {
  // If the stored language is not supported, set a fallback language
  i18n.changeLanguage('de'); // Replace 'de' with your desired fallback language
}

export default i18n;
