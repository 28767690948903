import { TEmployeeState } from '@/redux/reducers/employee';
import { TGetListEmployeeSuccess } from '@/redux/actions/employee';

export const getListEmployeeUpdateState = (state: TEmployeeState, action: TGetListEmployeeSuccess): TEmployeeState => {
  const updatedState = {
    ...state,
    getListEmployeeResponse: action.payload.response,
  };

  return updatedState;
};
