import { createReducer } from 'deox';
import { setDotncommaState } from './dotncomma';
import { TDotncommaRequest, saveDotncommaAction } from '@/redux/actions/dotncomma';

export type TEditState = {
  saveDotncommaResponse?: any;
};
const decimalPoint = localStorage.getItem('decimalPoint') || 'dot'; // Default to 'dot'

const initialState: TEditState = {
  saveDotncommaResponse: decimalPoint,
};

const SaveReducer = createReducer(initialState, (handleAction) => [
  handleAction(saveDotncommaAction.request, (state, action) => {
    const updatedState = setDotncommaState(state, action);

    return updatedState;
  }),
]);

export default SaveReducer;
