import { all, takeLatest } from 'redux-saga/effects';

import {
  createProjectAction,
  postProjectAction,
  deleteProjectAction,
  copyProjectAction,
  renameProjectAction,
} from '@/redux/actions/project';

import { postProjectSaga } from './post-project';
import { createProjectSaga } from './create-project';
import { deleteProjectSaga } from './delete-project';
import { copyProjectSaga } from './copy-project';
import { renameProjectSaga } from './rename-project';

export default function* root(): Generator {
  yield all([takeLatest(postProjectAction.request.type, postProjectSaga)]);
  yield all([takeLatest(createProjectAction.request.type, createProjectSaga)]);
  yield all([takeLatest(deleteProjectAction.request.type, deleteProjectSaga)]);
  yield all([takeLatest(copyProjectAction.request.type, copyProjectSaga)]);
  yield all([takeLatest(renameProjectAction.request.type, renameProjectSaga)]);
}
