import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TRenameProjectParams = unknown;

export type TRenameProjectResponse = TCommonResponse;

export type TRenameProjectMaterials = {
  name?: TRenameProjectParams;
  project_id?: TRenameProjectParams;
};

export const renameProject = async ({ name, project_id }: TRenameProjectMaterials): Promise<TRenameProjectResponse> => {
  const params = {
    'params': {
      'name': name?.toString(),
      'project_id': project_id?.toString(),
    },
  };
  const response = await ApiService.post(`/projects/api/updateProjectBasic/`, params);
  return response?.data; // Return the data from the response
};
