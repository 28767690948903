import ApiService from '@/services/api';
import axios from 'axios';

// TYPES

export type TAuthSignUpParams = unknown;
export type TAuthSignUpBody = unknown;

export type TAuthSignUpMaterials = {
  params?: TAuthSignUpParams;
};

export type TAuthSignUpResponse = unknown;

// FUNCTION

export const authSignUp = async ({ params }: TAuthSignUpMaterials): Promise<TAuthSignUpResponse> => {
  const response = await ApiService.post(`/users/api/register/`, params);
  return response?.data;
};
