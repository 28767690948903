import { all, takeLatest } from 'redux-saga/effects';

import { verifyEmailAction } from '@/redux/actions/company';
import { sendEmailAction } from '@/redux/actions/company';
import { upgradeLicenseAction } from '@/redux/actions/company';

import { verifyEmailSaga } from './verify-email';
import { sendEmailSaga } from './send-email';
import { upgradeLicenseSaga } from './upgrade-license';

export default function* root(): Generator {
  yield all([takeLatest(verifyEmailAction.request.type, verifyEmailSaga)]);
  yield all([takeLatest(sendEmailAction.request.type, sendEmailSaga)]);
  yield all([takeLatest(upgradeLicenseAction.request.type, upgradeLicenseSaga)]);
}
