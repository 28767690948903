import { TLocationState } from '@/redux/reducers/location';
import { TGetListStateSuccess } from '@/redux/actions/location';

export const getListStateUpdateState = (state: TLocationState, action: TGetListStateSuccess): TLocationState => {
  const updatedState = {
    ...state,
    getListStateResponse: action.payload.response,
  };

  return updatedState;
};
