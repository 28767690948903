import { TEmployeeState } from '@/redux/reducers/employee';
import { TGetEmployeeInfoSuccess } from '@/redux/actions/employee';

export const getEmployeeInfoUpdateState = (state: TEmployeeState, action: TGetEmployeeInfoSuccess): TEmployeeState => {
  const updatedState = {
    ...state,
    getEmployeeInfoResponse: action.payload.response,
  };

  return updatedState;
};
