import { TProjectExportState } from '@/redux/reducers/project-export';
import { TProjectExportXmlSuccess } from '@/redux/actions/project-export';

export const projectExportXmlUpdateState = (
  state: TProjectExportState,
  action: TProjectExportXmlSuccess,
): TProjectExportState => {
  const updatedState = {
    ...state,
    projectExportXmlResponse: action.payload.response,
  };

  return updatedState;
};
