import { TLocationState } from '@/redux/reducers/location';
import { TGetListCountrySuccess } from '@/redux/actions/location';

export const getListCountryUpdateState = (state: TLocationState, action: TGetListCountrySuccess): TLocationState => {
  const updatedState = {
    ...state,
    getListCountryResponse: action.payload.response,
  };

  return updatedState;
};
