import { TEmployeeState } from '@/redux/reducers/employee';
import { TDeleteListEmployeeSuccess } from '@/redux/actions/employee';

export const deleteListEmployeeUpdateState = (
  state: TEmployeeState,
  action: TDeleteListEmployeeSuccess,
): TEmployeeState => {
  const updatedState = {
    ...state,
    deleteListEmployeeResponse: action.payload.response,
  };
  return updatedState;
};
