import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TVerifyEmailParams = unknown;

export type TVerifyEmailResponse = TCommonResponse;

export type TVerifyEmailMaterials = {
  company_verify_email?: TVerifyEmailParams;
  otp?: TVerifyEmailParams;
};

export const verifyEmail = async ({
  company_verify_email,
  otp,
}: TVerifyEmailMaterials): Promise<TVerifyEmailResponse> => {
  const params = {
    'params': {
      'company_verify_email': company_verify_email,
      'otp': otp,
    },
  };
  const response = await ApiService.post('/companies/api/verifyOTPWithCompanyEmail/', params);
  return response?.data; // Return the data from the response
};
