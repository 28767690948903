import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetListEmployeeInsideParams = unknown;

export type TGetListEmployeeInsideResponse = TCommonResponse;

export type TGetListEmployeeInsideMaterials = {
  project_id?: TGetListEmployeeInsideParams; // Correct the property name to "project_id"
  company_id?: TGetListEmployeeInsideParams; // Correct the property name to "project_id"
};

export const getListEmployeeInside = async (params): Promise<TGetListEmployeeInsideResponse> => {
  const response = await ApiService.get(
    `/companies/api/getListEmployeesInsideProject/?company_id=${params.company_id}&project_id=${params.project_id}`,
  );
  return response?.data; // Return the data from the response to sagas
};
