import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios, { AxiosRequestConfig } from 'axios';

// TYPES

export type TDeleteListEmployeeParams = unknown;

export type TDeleteListEmployeeResponse = TCommonResponse;

export type TDeleteListEmployeeMaterials = {
  arrayList?: TDeleteListEmployeeParams;
  company_id?: TDeleteListEmployeeParams;
};

export const deleteListEmployee = async ({
  arrayList,
  company_id,
}: TDeleteListEmployeeMaterials): Promise<TDeleteListEmployeeResponse> => {
  const params: AxiosRequestConfig = {
    method: 'delete', // Specify the HTTP method
    url: `/companies/api/deleteListEmployees/`,
    data: {
      'params': {
        'list_employees': arrayList,
        'company_id': company_id,
      },
    },
  };
  const response = await ApiService(params);
  return response?.data; // Return the data from the response to sagas
};
