import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetProjectSettingParams = unknown;

export type TGetProjectSettingResponse = TCommonResponse;

export type TGetProjectSettingMaterials = {
  project_id?: TGetProjectSettingParams; // Correct the property name to "project_id"
};

export const getProjectSetting = async (id): Promise<TGetProjectSettingResponse> => {
  const response = await ApiService.get(`/projects/api/getProjectSettingData/?project_id=${id.project_id}`);
  return response?.data; // Return the data from the response
};
