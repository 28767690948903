import { createActionCreator } from 'deox';

import {
  TDeleteListEmployeeMaterials,
  TDeleteListEmployeeResponse,
} from '@/services/api/employee/delete-list-employee';

// CONSTANTS

export enum EDeleteListEmployeeAction {
  DELETE_LIST_EMPLOYEE = 'DELETE_LIST_EMPLOYEE',
  DELETE_LIST_EMPLOYEE_REQUEST = 'DELETE_LIST_EMPLOYEE_REQUEST',
  DELETE_LIST_EMPLOYEE_SUCCESS = 'DELETE_LIST_EMPLOYEE_SUCCESS',
  DELETE_LIST_EMPLOYEE_FAILED = 'DELETE_LIST_EMPLOYEE_FAILED',
}

// TYPES

export type TDeleteListEmployeeRequest = {
  type: EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_REQUEST;
  payload: {
    materials: TDeleteListEmployeeMaterials;
    successCallback?: (response: TDeleteListEmployeeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteListEmployeeSuccess = {
  type: EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_SUCCESS;
  payload: { response: TDeleteListEmployeeResponse };
};

export type TDeleteListEmployeeFailed = { type: EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_FAILED };

// FUNCTION

export const deleteListEmployeeAction = {
  request: createActionCreator(
    EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_REQUEST,
    (resolve) =>
      (
        materials: TDeleteListEmployeeMaterials,
        successCallback?: (response: TDeleteListEmployeeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteListEmployeeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_SUCCESS,
    (resolve) =>
      (response: TDeleteListEmployeeResponse): TDeleteListEmployeeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteListEmployeeAction.DELETE_LIST_EMPLOYEE_FAILED,
    (resolve) =>
      (error: any): TDeleteListEmployeeFailed =>
        resolve({ error }),
  ),
};
