import { createActionCreator } from 'deox';

import { TVerifyEmailMaterials, TVerifyEmailResponse } from '@/services/api/company';

// CONSTANTS

export enum EVerifyEmailAction {
  VERIFY_EMAIL = 'VERIFY_EMAIL',
  VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST',
  VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS',
  VERIFY_EMAIL_FAILED = 'VERIFY_EMAIL_FAILED',
}

// TYPES

export type TVerifyEmailRequest = {
  type: EVerifyEmailAction.VERIFY_EMAIL_REQUEST;
  payload: {
    materials: TVerifyEmailMaterials;
    successCallback?: (response: TVerifyEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVerifyEmailSuccess = {
  type: EVerifyEmailAction.VERIFY_EMAIL_SUCCESS;
  payload: { response: TVerifyEmailResponse };
};

export type TVerifyEmailFailed = { type: EVerifyEmailAction.VERIFY_EMAIL_FAILED };

// FUNCTION

export const verifyEmailAction = {
  request: createActionCreator(
    EVerifyEmailAction.VERIFY_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TVerifyEmailMaterials,
        successCallback?: (response: TVerifyEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVerifyEmailRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EVerifyEmailAction.VERIFY_EMAIL_SUCCESS,
    (resolve) =>
      (response: TVerifyEmailResponse): TVerifyEmailSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EVerifyEmailAction.VERIFY_EMAIL_FAILED,
    (resolve) =>
      (error: any): TVerifyEmailFailed =>
        resolve({ error }),
  ),
};
