import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import authReducer from './auth';
import uiReducer from './ui';
import interactionReducer from './interaction';
import gridSelectedReducer from './grid-selected';
import dotncommaReducer from './dotncomma';
import EmployeeReducer from './employee';
import disabledatagridReducer from './disable-datagrid';
import projectDetailReducer from './project-detail';
import projectSettingReducer from './project-setting';
import projectExportReducer from './project-export';
import termConditionReducer from './general';
import CompanyReducer from './company';
import projectReducer from './project';
import DataProjectReducer from './data-project';
import LocationReducer from './location';

const rootReducer = combineReducers({
  loadingReducer,
  authReducer,
  CompanyReducer,
  uiReducer,
  DataProjectReducer,
  interactionReducer,
  EmployeeReducer,
  gridSelectedReducer,
  dotncommaReducer,
  disabledatagridReducer,
  projectDetailReducer,
  projectSettingReducer,
  projectExportReducer,
  termConditionReducer,
  projectReducer,
  LocationReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
