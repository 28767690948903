import { TEmployeeState } from '@/redux/reducers/employee';
import { TAssignUsersListSuccess } from '@/redux/actions/employee';

export const assignUsersListUpdateState = (state: TEmployeeState, action: TAssignUsersListSuccess): TEmployeeState => {
  const updatedState = {
    ...state,
    assignUsersListResponse: action.payload.response,
  };
  return updatedState;
};
