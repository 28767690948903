import { createActionCreator } from 'deox';

import { TGetListCountryResponse } from '@/services/api/location';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListCountryAction {
  GET_LIST_COUNTRY = 'GET_LIST_COUNTRY',
  GET_LIST_COUNTRY_REQUEST = 'GET_LIST_COUNTRY_REQUEST',
  GET_LIST_COUNTRY_SUCCESS = 'GET_LIST_COUNTRY_SUCCESS',
  GET_LIST_COUNTRY_FAILED = 'GET_LIST_COUNTRY_FAILED',
}

// TYPES

export type TGetListCountryRequest = {
  type: EGetListCountryAction.GET_LIST_COUNTRY_REQUEST;
  payload: {
    successCallback?: (response: TGetListCountryResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListCountrySuccess = {
  type: EGetListCountryAction.GET_LIST_COUNTRY_SUCCESS;
  payload: { response: TGetListCountryResponse };
};

export type TGetListCountryFailed = { type: EGetListCountryAction.GET_LIST_COUNTRY_FAILED };

// FUNCTION

export const getListCountryAction = {
  request: createActionCreator(
    EGetListCountryAction.GET_LIST_COUNTRY_REQUEST,
    (resolve) =>
      (
        successCallback?: (response: TGetListCountryResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListCountryRequest => {
        return resolve({ successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListCountryAction.GET_LIST_COUNTRY_SUCCESS,
    (resolve) =>
      (response: TGetListCountryResponse): TGetListCountrySuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListCountryAction.GET_LIST_COUNTRY_FAILED,
    (resolve) =>
      (error: any): TGetListCountryFailed => {
        message.error('GET_LIST_COUNTRY_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
