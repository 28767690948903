import { TProjectDetailState } from '@/redux/reducers/project-detail';
import { TGetProjectDetailSuccess } from '@/redux/actions/project-detail';

export const getProjectDetailUpdateState = (
  state: TProjectDetailState,
  action: TGetProjectDetailSuccess,
): TProjectDetailState => {
  const updatedState = {
    ...state,
    getProjectDetailResponse: action.payload.response,
  };

  return updatedState;
};
