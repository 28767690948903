import { TEmployeeState } from '@/redux/reducers/employee';
import { TGetListProjectSuccess } from '@/redux/actions/employee';

export const getListProjectUpdateState = (state: TEmployeeState, action: TGetListProjectSuccess): TEmployeeState => {
  const updatedState = {
    ...state,
    getListProjectResponse: action.payload.response,
  };

  return updatedState;
};
