import { TProjectExportState } from '@/redux/reducers/project-export';
import { TProjectExportExcelSuccess } from '@/redux/actions/project-export';

export const projectExportExcelUpdateState = (
  state: TProjectExportState,
  action: TProjectExportExcelSuccess,
): TProjectExportState => {
  const updatedState = {
    ...state,
    projectExportExcelResponse: action.payload.response,
  };

  return updatedState;
};
