import { createActionCreator } from 'deox';

import { TDeleteProjectMaterials, TDeleteProjectResponse } from '@/services/api/project/delete-project';

// CONSTANTS

export enum EDeleteProjectAction {
  DELETE_PROJECT = 'DELETE_PROJECT',
  DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST',
  DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS',
  DELETE_PROJECT_FAILED = 'DELETE_PROJECT_FAILED',
}

// TYPES

export type TDeleteProjectRequest = {
  type: EDeleteProjectAction.DELETE_PROJECT_REQUEST;
  payload: {
    materials: TDeleteProjectMaterials;
    successCallback?: (response: TDeleteProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TDeleteProjectSuccess = {
  type: EDeleteProjectAction.DELETE_PROJECT_SUCCESS;
  payload: { response: TDeleteProjectResponse };
};

export type TDeleteProjectFailed = { type: EDeleteProjectAction.DELETE_PROJECT_FAILED };

// FUNCTION

export const deleteProjectAction = {
  request: createActionCreator(
    EDeleteProjectAction.DELETE_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TDeleteProjectMaterials,
        successCallback?: (response: TDeleteProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TDeleteProjectRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EDeleteProjectAction.DELETE_PROJECT_SUCCESS,
    (resolve) =>
      (response: TDeleteProjectResponse): TDeleteProjectSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EDeleteProjectAction.DELETE_PROJECT_FAILED,
    (resolve) =>
      (error: any): TDeleteProjectFailed =>
        resolve({ error }),
  ),
};
