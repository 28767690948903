import { TAuthState } from '@/redux/reducers/auth';
import { TRequestVerifyUserEmailSuccess } from '@/redux/actions/auth';

export const requestVerifyUserEmailUpdateState = (
  state: TAuthState,
  action: TRequestVerifyUserEmailSuccess,
): TAuthState => ({
  ...state,
  requestVerifyUserEmailResponse: action.payload.response,
});
