import { createReducer } from 'deox';

import { TSendEmailResponse } from '@/services/api/company';
import { sendEmailAction } from '@/redux/actions/company';
import { sendEmailUpdateState } from './send-email';

import { TVerifyEmailResponse } from '@/services/api/company';
import { verifyEmailAction } from '@/redux/actions/company';
import { verifyEmailUpdateState } from './verify-email';

import { TUpgradeLicenseResponse } from '@/services/api/company';
import { upgradeLicenseAction } from '@/redux/actions/company';
import { upgradeLicenseUpdateState } from './upgrade-license';

export type TCompanyState = {
  sendEmailResponse?: TSendEmailResponse;
  verifyEmailResponse?: TVerifyEmailResponse;
  upgradeLicenseResponse?: TUpgradeLicenseResponse;
};

const initialState: TCompanyState = {
  sendEmailResponse: undefined,
  verifyEmailResponse: undefined,
  upgradeLicenseResponse: undefined,
};

const CompanyReducer = createReducer(initialState, (handleAction) => [
  handleAction(sendEmailAction.success, (state, action) => {
    const updatedState = sendEmailUpdateState(state, action);

    return updatedState;
  }),

  handleAction(verifyEmailAction.success, (state, action) => {
    const updatedState = verifyEmailUpdateState(state, action);

    return updatedState;
  }),

  handleAction(upgradeLicenseAction.success, (state, action) => {
    const updatedState = upgradeLicenseUpdateState(state, action);

    return updatedState;
  }),
]);

export default CompanyReducer;
