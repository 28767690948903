import { createActionCreator } from 'deox';

import { TCreateProjectMaterials, TCreateProjectResponse } from '@/services/api/project/create-project';

// CONSTANTS

export enum ECreateProjectAction {
  CREATE_PROJECT = 'CREATE_PROJECT',
  CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST',
  CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS',
  CREATE_PROJECT_FAILED = 'CREATE_PROJECT_FAILED',
}

// TYPES

export type TCreateProjectRequest = {
  type: ECreateProjectAction.CREATE_PROJECT_REQUEST;
  payload: {
    materials: TCreateProjectMaterials;
    successCallback?: (response: TCreateProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TCreateProjectSuccess = {
  type: ECreateProjectAction.CREATE_PROJECT_SUCCESS;
  payload: { response: TCreateProjectResponse };
};

export type TCreateProjectFailed = { type: ECreateProjectAction.CREATE_PROJECT_FAILED };

// FUNCTION

export const createProjectAction = {
  request: createActionCreator(
    ECreateProjectAction.CREATE_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TCreateProjectMaterials,
        successCallback?: (response: TCreateProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TCreateProjectRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    ECreateProjectAction.CREATE_PROJECT_SUCCESS,
    (resolve) =>
      (response: TCreateProjectResponse): TCreateProjectSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    ECreateProjectAction.CREATE_PROJECT_FAILED,
    (resolve) =>
      (error: any): TCreateProjectFailed =>
        resolve({ error }),
  ),
};
