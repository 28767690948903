import { createActionCreator } from 'deox';

// CONSTANTS

export enum EGuidedTourAction {
  GUIDED_TOUR = 'GUIDED_TOUR',
  GUIDED_TOUR_REQUEST = 'GUIDED_TOUR_REQUEST',
  GUIDED_TOUR_SUCCESS = 'GUIDED_TOUR_SUCCESS',
  GUIDED_TOUR_FAILED = 'GUIDED_TOUR_FAILED',
}

// TYPES

export type TGuidedTourRequest = {
  type: EGuidedTourAction.GUIDED_TOUR_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TGuidedTourSuccess = {
  type: EGuidedTourAction.GUIDED_TOUR_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TGuidedTourFailed = {
  type: EGuidedTourAction.GUIDED_TOUR_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const toggleTourAction = {
  request: createActionCreator(
    EGuidedTourAction.GUIDED_TOUR_REQUEST,
    (resolve) =>
      (payload: { isTour: any }): TGuidedTourRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EGuidedTourAction.GUIDED_TOUR_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TGuidedTourSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EGuidedTourAction.GUIDED_TOUR_FAILED,
    (resolve) => (/* Pass your payload data here */): TGuidedTourFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
