import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getEmployeeInfoAction } from '@/redux/actions/employee';
import { getEmployeeInfo, TGetEmployeeInfoResponse } from '@/services/api/employee';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

export function* getEmployeeInfoSaga(action: ActionType<typeof getEmployeeInfoAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getEmployeeInfo, materials);
    const getEmployeeInfoResponse: TGetEmployeeInfoResponse = response as TGetEmployeeInfoResponse;
    yield put(getEmployeeInfoAction.success(getEmployeeInfoResponse));
    successCallback?.(getEmployeeInfoResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getEmployeeInfoError');
    yield put(getEmployeeInfoAction.failure(err));
    failedCallback?.(err);
  }
}
