import { TAuthState } from '@/redux/reducers/auth';
import { TResetPasswordSuccess } from '@/redux/actions/auth';

export const resetPasswordUpdateState = (state: TAuthState, action: TResetPasswordSuccess): TAuthState => {
  const updatedState = {
    ...state,
    resetPasswordResponse: action.payload.response,
  };

  return updatedState;
};
