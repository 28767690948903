import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpdateProfileEmployeeParams = unknown;

export type TUpdateProfileEmployeeResponse = TCommonResponse;

export type TUpdateProfileEmployeeMaterials = {
  params?: TUpdateProfileEmployeeParams;
};

export const updateProfileEmployee = async ({
  params,
}: TUpdateProfileEmployeeMaterials): Promise<TUpdateProfileEmployeeResponse> => {
  const response = await ApiService.post('/companies/api/editEmployeeInfo/', params);
  return response?.data; // Return the data from the response to sagas
};
