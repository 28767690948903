import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TProjectExportXmlParams = unknown;

export type TProjectExportXmlResponse = TCommonResponse;

export type TProjectExportXmlMaterials = {
  project_id?: TProjectExportXmlParams; // Correct the property name to "project_id"
};

export const projectExportXml = async (id): Promise<TProjectExportXmlResponse> => {
  const response = await ApiService.get(`/projects/api/getProjectXMLFile/?project_id=${id.project_id}`);
  return response?.data; // Return the data from the response
};
