import env from '@/env';
import AuthorizedInstance from '@/services/authorized-api';
const ApiService = AuthorizedInstance(env.api.baseUrl.service);

export default ApiService;
export * from './auth';
export * from './project-detail';
export * from './project';
export * from './project-setting';
export * from './project-export';
export * from './general';
export * from './employee';
export * from './company';
export * from './location';
