import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TPostCompanySettingParams = unknown;

export type TPostCompanySettingMaterials = {
  entries(): unknown;
  dataSubmit?: any;
};

export type TPostCompanySettingResponse = TCommonResponse;

export const postCompanySetting = async (
  dataSubmit: TPostCompanySettingMaterials,
): Promise<TPostCompanySettingResponse> => {
  const data: any = await dataSubmit;
  const response = await ApiService.post(`/projects/api/updateCompanyInfoInSetting/`, data);
  return response?.data; // Return the data from the response
};
