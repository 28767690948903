import { createActionCreator } from 'deox';

import { TGetProjectDetailMaterials, TGetProjectDetailResponse } from '@/services/api/project-detail';
import message from '@/utils/message';

// CONSTANTS

export enum EGetProjectDetailAction {
  GET_PROJECTDETAIL = 'GET_PROJECTDETAIL',
  GET_PROJECTDETAIL_REQUEST = 'GET_PROJECTDETAIL_REQUEST',
  GET_PROJECTDETAIL_SUCCESS = 'GET_PROJECTDETAIL_SUCCESS',
  GET_PROJECTDETAIL_FAILED = 'GET_PROJECTDETAIL_FAILED',
}

// TYPES

export type TGetProjectDetailRequest = {
  type: EGetProjectDetailAction.GET_PROJECTDETAIL_REQUEST;
  payload: {
    materials: TGetProjectDetailMaterials;
    successCallback?: (response: TGetProjectDetailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetProjectDetailSuccess = {
  type: EGetProjectDetailAction.GET_PROJECTDETAIL_SUCCESS;
  payload: { response: TGetProjectDetailResponse };
};

export type TGetProjectDetailFailed = { type: EGetProjectDetailAction.GET_PROJECTDETAIL_FAILED };

// FUNCTION

export const getProjectDetailAction = {
  request: createActionCreator(
    EGetProjectDetailAction.GET_PROJECTDETAIL_REQUEST,
    (resolve) =>
      (
        materials: TGetProjectDetailMaterials,
        successCallback?: (response: TGetProjectDetailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetProjectDetailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetProjectDetailAction.GET_PROJECTDETAIL_SUCCESS,
    (resolve) =>
      (response: TGetProjectDetailResponse): TGetProjectDetailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetProjectDetailAction.GET_PROJECTDETAIL_FAILED,
    (resolve) =>
      (error: any): TGetProjectDetailFailed => {
        message.error('GET_PROJECTDETAIL_FAILED Action:', error.data.status_message);
        return resolve({ error });
      },
  ),
};
