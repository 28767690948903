import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetTermConditionParams = unknown;

export type TGetTermConditionResponse = TCommonResponse;

export type TGetTermConditionMaterials = {
  id?: TGetTermConditionParams; // Correct the property name to "project_id"
};

export const getTermCondition = async (id): Promise<TGetTermConditionResponse> => {
  try {
    const response = await ApiService.get(`/general/api/getTermConditionURL/`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
