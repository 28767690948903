import { createActionCreator } from 'deox';

import { TGetProjectSettingMaterials, TGetProjectSettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// CONSTANTS

export enum EGetProjectSettingAction {
  GET_PROJECTSETTING = 'GET_PROJECTSETTING',
  GET_PROJECTSETTING_REQUEST = 'GET_PROJECTSETTING_REQUEST',
  GET_PROJECTSETTING_SUCCESS = 'GET_PROJECTSETTING_SUCCESS',
  GET_PROJECTSETTING_FAILED = 'GET_PROJECTSETTING_FAILED',
}

// TYPES

export type TGetProjectSettingRequest = {
  type: EGetProjectSettingAction.GET_PROJECTSETTING_REQUEST;
  payload: {
    materials: TGetProjectSettingMaterials;
    successCallback?: (response: TGetProjectSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetProjectSettingSuccess = {
  type: EGetProjectSettingAction.GET_PROJECTSETTING_SUCCESS;
  payload: { response: TGetProjectSettingResponse };
};

export type TGetProjectSettingFailed = { type: EGetProjectSettingAction.GET_PROJECTSETTING_FAILED };

// FUNCTION

export const getProjectSettingAction = {
  request: createActionCreator(
    EGetProjectSettingAction.GET_PROJECTSETTING_REQUEST,
    (resolve) =>
      (
        materials: TGetProjectSettingMaterials,
        successCallback?: (response: TGetProjectSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetProjectSettingRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetProjectSettingAction.GET_PROJECTSETTING_SUCCESS,
    (resolve) =>
      (response: TGetProjectSettingResponse): TGetProjectSettingSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetProjectSettingAction.GET_PROJECTSETTING_FAILED,
    (resolve) =>
      (error: any): TGetProjectSettingFailed => {
        message.error('GET_PROJECTSETTING_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
