import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TGetBackendVersionParams = unknown;

export type TGetBackendVersionResponse = TCommonResponse;

export const getBackendVersion = async (): Promise<TGetBackendVersionResponse> => {
  try {
    const response = await ApiService.get(`/general/api/getBackendVersion/`);
    return response; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
