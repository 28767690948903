import { TAuthState } from '@/redux/reducers/auth';
import { TResendOtpSuccess } from '@/redux/actions/auth';

export const resendOtpUpdateState = (state: TAuthState, action: TResendOtpSuccess): TAuthState => {
  const updatedState = {
    ...state,
    resendOtpResponse: action.payload.response,
  };

  return updatedState;
};
