import { all, takeLatest } from 'redux-saga/effects';

import {
  getProjectSettingAction,
  postCompanySettingAction,
  postProjectSettingAction,
} from '@/redux/actions/project-setting';

import { getProjectSettingSaga } from './get-project-setting';
import { postCompanySettingSaga } from './post-company-setting';
import { postProjectSettingSaga } from './post-project-setting';

export default function* root(): Generator {
  yield all([takeLatest(getProjectSettingAction.request.type, getProjectSettingSaga)]);
  yield all([takeLatest(postProjectSettingAction.request.type, postProjectSettingSaga)]);
  yield all([takeLatest(postCompanySettingAction.request.type, postCompanySettingSaga)]);
}
