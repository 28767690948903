import { createActionCreator } from 'deox';

// CONSTANTS

export enum EDotncommaAction {
  DOTNCOMMA = 'DOTNCOMMA',
  DOTNCOMMA_REQUEST = 'DOTNCOMMA_REQUEST',
  DOTNCOMMA_SUCCESS = 'DOTNCOMMA_SUCCESS',
  DOTNCOMMA_FAILED = 'DOTNCOMMA_FAILED',
}

// TYPES

export type TDotncommaRequest = {
  type: EDotncommaAction.DOTNCOMMA_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TDotncommaSuccess = {
  type: EDotncommaAction.DOTNCOMMA_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TDotncommaFailed = {
  type: EDotncommaAction.DOTNCOMMA_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const saveDotncommaAction = {
  request: createActionCreator(
    EDotncommaAction.DOTNCOMMA_REQUEST,
    (resolve) =>
      (payload: { status: string }): TDotncommaRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EDotncommaAction.DOTNCOMMA_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TDotncommaSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EDotncommaAction.DOTNCOMMA_FAILED,
    (resolve) => (/* Pass your payload data here */): TDotncommaFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
