import { createReducer } from 'deox';
import { TGetProjectSettingResponse, TPostCompanySettingResponse } from '@/services/api/project-setting';
import { TPostProjectSettingResponse } from '@/services/api/project-setting';
import { getProjectSettingAction, postCompanySettingAction } from '@/redux/actions/project-setting';
import { postProjectSettingAction } from '@/redux/actions/project-setting';
import { getProjectSettingUpdateState } from './get-project-setting';
import { postProjectSettingUpdateState } from './post-project-setting';
import { postCompanySettingUpdateState } from './post-company-setting';

export type TProjectSettingState = {
  getProjectSettingResponse?: TGetProjectSettingResponse;
  postProjectSettingResponse?: TPostProjectSettingResponse;
  postCompanySettingResponse?: TPostCompanySettingResponse;
};

const initialState: TProjectSettingState = {
  getProjectSettingResponse: undefined,
  postProjectSettingResponse: undefined,
  postCompanySettingResponse: undefined,
};

const ProjectSettingReducer = createReducer(initialState, (handleAction) => [
  handleAction(getProjectSettingAction.success, (state, action) => {
    // Call the getProjectSettingUpdateState function
    const updatedState = getProjectSettingUpdateState(state, action);

    return updatedState;
  }),
  handleAction(postProjectSettingAction.success, (state, action) => {
    // Call the saveProjectDetailUpdateState function
    const updatedState = postProjectSettingUpdateState(state, action);

    return updatedState;
  }),
  handleAction(postCompanySettingAction.success, (state, action) => {
    // Call the saveProjectDetailUpdateState function
    const updatedState = postCompanySettingUpdateState(state, action);

    return updatedState;
  }),
]);

export default ProjectSettingReducer;
