import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { verifyUserEmailAction } from '@/redux/actions';
import { verifyUserEmail, TVerifyUserEmailResponse } from '@/services/api';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* verifyUserEmailSaga(action: ActionType<typeof verifyUserEmailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(verifyUserEmail, materials);
    const verifyUserEmailResponse: TVerifyUserEmailResponse = response as TVerifyUserEmailResponse;
    yield put(verifyUserEmailAction.success(verifyUserEmailResponse));
    successCallback?.(verifyUserEmailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.verifyOTPUserEmailError');
    yield put(verifyUserEmailAction.failure(err));
    failedCallback?.(err);
  }
}
