import { TEmployeeState } from '@/redux/reducers/employee';
import { TGetListEmployeeInsideSuccess } from '@/redux/actions/employee';

export const getListEmployeeInsideUpdateState = (
  state: TEmployeeState,
  action: TGetListEmployeeInsideSuccess,
): TEmployeeState => {
  const updatedState = {
    ...state,
    getListEmployeeInsideResponse: action.payload.response,
  };

  return updatedState;
};
