import { createReducer } from 'deox';
import { TPostProjectDetailResponse } from '@/services/api/project-detail';
import { TGetProjectDetailResponse } from '@/services/api/project-detail';
import { TSaveProjectDetailResponse } from '@/services/api/project-detail';
import { postProjectDetailAction } from '@/redux/actions/project-detail';
import { getProjectDetailAction } from '@/redux/actions/project-detail';
import { saveProjectDetailAction } from '@/redux/actions/project-detail';
import { postProjectDetailUpdateState } from './post-project-detail';
import { getProjectDetailUpdateState } from './get-project-detail';
import { saveProjectDetailUpdateState } from './save-project-detail';

export type TProjectDetailState = {
  postProjectDetailResponse?: TPostProjectDetailResponse;
  getProjectDetailResponse?: TGetProjectDetailResponse;
  saveProjectDetailResponse?: TSaveProjectDetailResponse;
};

const initialState: TProjectDetailState = {
  postProjectDetailResponse: undefined,
  getProjectDetailResponse: undefined,
  saveProjectDetailResponse: undefined,
};

const ProjectDetailReducer = createReducer(initialState, (handleAction) => [
  handleAction(postProjectDetailAction.success, (state, action) => {
    // Call the postProjectDetailUpdateState function
    const updatedState = postProjectDetailUpdateState(state, action);

    return updatedState;
  }),
  handleAction(getProjectDetailAction.success, (state, action) => {
    // Call the getProjectDetailUpdateState function
    const updatedState = getProjectDetailUpdateState(state, action);

    return updatedState;
  }),
  handleAction(saveProjectDetailAction.success, (state, action) => {
    // Call the saveProjectDetailUpdateState function
    const updatedState = saveProjectDetailUpdateState(state, action);

    return updatedState;
  }),
]);

export default ProjectDetailReducer;
