import { createActionCreator } from 'deox';

import { TGetListStateMaterials, TGetListStateResponse } from '@/services/api/location';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListStateAction {
  GET_LIST_STATE = 'GET_LIST_STATE',
  GET_LIST_STATE_REQUEST = 'GET_LIST_STATE_REQUEST',
  GET_LIST_STATE_SUCCESS = 'GET_LIST_STATE_SUCCESS',
  GET_LIST_STATE_FAILED = 'GET_LIST_STATE_FAILED',
}

// TYPES

export type TGetListStateRequest = {
  type: EGetListStateAction.GET_LIST_STATE_REQUEST;
  payload: {
    materials: TGetListStateMaterials;
    successCallback?: (response: TGetListStateResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListStateSuccess = {
  type: EGetListStateAction.GET_LIST_STATE_SUCCESS;
  payload: { response: TGetListStateResponse };
};

export type TGetListStateFailed = { type: EGetListStateAction.GET_LIST_STATE_FAILED };

// FUNCTION

export const getListStateAction = {
  request: createActionCreator(
    EGetListStateAction.GET_LIST_STATE_REQUEST,
    (resolve) =>
      (
        materials: TGetListStateMaterials,
        successCallback?: (response: TGetListStateResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListStateRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListStateAction.GET_LIST_STATE_SUCCESS,
    (resolve) =>
      (response: TGetListStateResponse): TGetListStateSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListStateAction.GET_LIST_STATE_FAILED,
    (resolve) =>
      (error: any): TGetListStateFailed => {
        message.error('GET_LIST_STATE_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
