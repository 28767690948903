import { all, takeLatest } from 'redux-saga/effects';

import { projectExportXmlAction } from '@/redux/actions/project-export';
import { projectExportExcelAction } from '@/redux/actions/project-export';
import { projectExportPdfAction } from '@/redux/actions/project-export';

import { projectExportXmlSaga } from './project-export-xml';
import { projectExportExcelSaga } from './project-export-excel';
import { projectExportPdfSaga } from './project-export-pdf';

export default function* root(): Generator {
  yield all([takeLatest(projectExportXmlAction.request.type, projectExportXmlSaga)]);
  yield all([takeLatest(projectExportExcelAction.request.type, projectExportExcelSaga)]);
  yield all([takeLatest(projectExportPdfAction.request.type, projectExportPdfSaga)]);
}
