import { TProjectDetailState } from '@/redux/reducers/project-detail';
import { TSaveProjectDetailSuccess } from '@/redux/actions/project-detail';

export const saveProjectDetailUpdateState = (
  state: TProjectDetailState,
  action: TSaveProjectDetailSuccess,
): TProjectDetailState => {
  const updatedState = {
    ...state,
    saveProjectDetailResponse: action.payload.response,
  };

  return updatedState;
};
