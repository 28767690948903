import { TCompanyState } from '@/redux/reducers/company';
import { TUpgradeLicenseSuccess } from '@/redux/actions/company';

export const upgradeLicenseUpdateState = (state: TCompanyState, action: TUpgradeLicenseSuccess): TCompanyState => {
  const updatedState = {
    ...state,
    upgradeLicenseResponse: action.payload.response,
  };
  return updatedState;
};
