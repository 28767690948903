import { createActionCreator } from 'deox';

// CONSTANTS

export enum EStoreNameAction {
  STORE_NAME = 'STORE_NAME',
  STORE_NAME_REQUEST = 'STORE_NAME_REQUEST',
  STORE_NAME_SUCCESS = 'STORE_NAME_SUCCESS',
  STORE_NAME_FAILED = 'STORE_NAME_FAILED',
}

// TYPES

export type TStoreNameRequest = {
  type: EStoreNameAction.STORE_NAME_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TStoreNameSuccess = {
  type: EStoreNameAction.STORE_NAME_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TStoreNameFailed = {
  type: EStoreNameAction.STORE_NAME_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const storeNameAction = {
  request: createActionCreator(
    EStoreNameAction.STORE_NAME_REQUEST,
    (resolve) =>
      (payload: { isStoreName: any }): TStoreNameRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EStoreNameAction.STORE_NAME_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TStoreNameSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EStoreNameAction.STORE_NAME_FAILED,
    (resolve) => (/* Pass your payload data here */): TStoreNameFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
