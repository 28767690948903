import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { projectExportExcelAction } from '@/redux/actions/project-export';
import { projectExportExcel, TProjectExportExcelResponse } from '@/services/api/project-export';
import message from '@/utils/message';

// FUNCTION

export function* projectExportExcelSaga(action: ActionType<typeof projectExportExcelAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(projectExportExcel, materials);
    const projectExportExcelResponse: TProjectExportExcelResponse = response as TProjectExportExcelResponse;
    yield put(projectExportExcelAction.success(projectExportExcelResponse));
    successCallback?.(projectExportExcelResponse);
  } catch (err) {
    message.error('projectExportExcelSaga error:', err.data.status_message);
    yield put(projectExportExcelAction.failure(err));
    failedCallback?.(err);
  }
}
