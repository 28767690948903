import { createReducer } from 'deox';

import { TGetListCountryResponse } from '@/services/api/location';
import { getListCountryAction } from '@/redux/actions/location';
import { getListCountryUpdateState } from './get-list-country';

import { TGetListStateResponse } from '@/services/api/location';
import { getListStateAction } from '@/redux/actions/location';
import { getListStateUpdateState } from './get-list-state';

export type TLocationState = {
  getListStateResponse?: TGetListStateResponse;
  getListCountryResponse?: TGetListCountryResponse;
};

const initialState: TLocationState = {
  getListStateResponse: undefined,
  getListCountryResponse: undefined,
};

const LocationReducer = createReducer(initialState, (handleAction) => [
  handleAction(getListStateAction.success, (state, action) => {
    // Call the getListStateUpdateState function
    const updatedState = getListStateUpdateState(state, action);

    return updatedState;
  }),

  handleAction(getListCountryAction.success, (state, action) => {
    // Call the getListStateUpdateState function
    const updatedState = getListCountryUpdateState(state, action);

    return updatedState;
  }),
]);

export default LocationReducer;
