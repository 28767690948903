import { createActionCreator } from 'deox';

import { TGetListEmployeeMaterials, TGetListEmployeeResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListEmployeeAction {
  GET_LIST_EMPLOYEE = 'GET_LIST_EMPLOYEE',
  GET_LIST_EMPLOYEE_REQUEST = 'GET_LIST_EMPLOYEE_REQUEST',
  GET_LIST_EMPLOYEE_SUCCESS = 'GET_LIST_EMPLOYEE_SUCCESS',
  GET_LIST_EMPLOYEE_FAILED = 'GET_LIST_EMPLOYEE_FAILED',
}

// TYPES

export type TGetListEmployeeRequest = {
  type: EGetListEmployeeAction.GET_LIST_EMPLOYEE_REQUEST;
  payload: {
    materials: TGetListEmployeeMaterials;
    successCallback?: (response: TGetListEmployeeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListEmployeeSuccess = {
  type: EGetListEmployeeAction.GET_LIST_EMPLOYEE_SUCCESS;
  payload: { response: TGetListEmployeeResponse };
};

export type TGetListEmployeeFailed = { type: EGetListEmployeeAction.GET_LIST_EMPLOYEE_FAILED };

// FUNCTION

export const getListEmployeeAction = {
  request: createActionCreator(
    EGetListEmployeeAction.GET_LIST_EMPLOYEE_REQUEST,
    (resolve) =>
      (
        materials: TGetListEmployeeMaterials,
        successCallback?: (response: TGetListEmployeeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListEmployeeRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListEmployeeAction.GET_LIST_EMPLOYEE_SUCCESS,
    (resolve) =>
      (response: TGetListEmployeeResponse): TGetListEmployeeSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListEmployeeAction.GET_LIST_EMPLOYEE_FAILED,
    (resolve) =>
      (error: any): TGetListEmployeeFailed => {
        message.error('GET_LIST_EMPLOYEE_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
