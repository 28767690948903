import { all, takeLatest } from 'redux-saga/effects';

import { getListCountryAction } from '@/redux/actions/location';
import { getListStateAction } from '@/redux/actions/location';

import { getListCountrySaga } from './get-list-country';
import { getListStateSaga } from './get-list-state';

export default function* root(): Generator {
  yield all([takeLatest(getListCountryAction.request.type, getListCountrySaga)]);
  yield all([takeLatest(getListStateAction.request.type, getListStateSaga)]);
}
