import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { sendMailAction } from '@/redux/actions/auth';
import { sendMail, TSendMailResponse } from '@/services/api/auth';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* sendMailSaga(action: ActionType<typeof sendMailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(sendMail, materials);
    const sendMailResponse: TSendMailResponse = response as TSendMailResponse;
    yield put(sendMailAction.success(sendMailResponse));
    successCallback?.(sendMailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.requestResetPasswordEmailError');
    yield put(sendMailAction.failure(err));
    failedCallback?.(err);
  }
}
