import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TSendEmailParams = unknown;

export type TSendEmailResponse = TCommonResponse;

export type TSendEmailMaterials = {
  company_verify_email?: TSendEmailParams; // Correct the property name to "project_id"
};

export const sendEmail = async (params): Promise<TSendEmailResponse> => {
  const response = await ApiService.get(
    `/companies/api/requestVerifyCompanyEmail/?company_verify_email=${params.company_verify_email}`,
  );
  return response?.data;
};
