import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { resetPasswordAction } from '@/redux/actions/auth';
import { resetPassword, TResetPasswordResponse } from '@/services/api/auth';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* resetPasswordSaga(action: ActionType<typeof resetPasswordAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(resetPassword, materials);
    const resetPasswordResponse: TResetPasswordResponse = response as TResetPasswordResponse;
    yield put(resetPasswordAction.success(resetPasswordResponse));
    successCallback?.(resetPasswordResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(resetPasswordAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.resetPasswordByOTPError');
  }
}
