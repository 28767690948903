import { createActionCreator } from 'deox';

import { TGetTermConditionMaterials, TGetTermConditionResponse } from '@/services/api/general';
import message from '@/utils/message';

// CONSTANTS

export enum EGetTermConditionAction {
  GET_TERMCONDITION = 'GET_TERMCONDITION',
  GET_TERMCONDITION_REQUEST = 'GET_TERMCONDITION_REQUEST',
  GET_TERMCONDITION_SUCCESS = 'GET_TERMCONDITION_SUCCESS',
  GET_TERMCONDITION_FAILED = 'GET_TERMCONDITION_FAILED',
}

// TYPES

export type TGetTermConditionRequest = {
  type: EGetTermConditionAction.GET_TERMCONDITION_REQUEST;
  payload: {
    materials: TGetTermConditionMaterials;
    successCallback?: (response: TGetTermConditionResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetTermConditionSuccess = {
  type: EGetTermConditionAction.GET_TERMCONDITION_SUCCESS;
  payload: { response: TGetTermConditionResponse };
};

export type TGetTermConditionFailed = { type: EGetTermConditionAction.GET_TERMCONDITION_FAILED };

// FUNCTION

export const getTermConditionAction = {
  request: createActionCreator(
    EGetTermConditionAction.GET_TERMCONDITION_REQUEST,
    (resolve) =>
      (
        materials: TGetTermConditionMaterials,
        successCallback?: (response: TGetTermConditionResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetTermConditionRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetTermConditionAction.GET_TERMCONDITION_SUCCESS,
    (resolve) =>
      (response: TGetTermConditionResponse): TGetTermConditionSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetTermConditionAction.GET_TERMCONDITION_FAILED,
    (resolve) =>
      (error: any): TGetTermConditionFailed => {
        message.error('GET_TERMCONDITION_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
