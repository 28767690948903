import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { deleteListEmployeeAction } from '@/redux/actions/employee';
import { deleteListEmployee, TDeleteListEmployeeResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

export function* deleteListEmployeeSaga(action: ActionType<typeof deleteListEmployeeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(deleteListEmployee, materials);
    const deleteListEmployeeResponse: TDeleteListEmployeeResponse = response as TDeleteListEmployeeResponse;
    yield put(deleteListEmployeeAction.success(deleteListEmployeeResponse));
    successCallback?.(deleteListEmployeeResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(deleteListEmployeeAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.deleteListEmployeesError');
  }
}
