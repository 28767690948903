import ApiService from '@/services/api';

// TYPES
export type TRequestVerifyCompanyEmailParams = unknown;

export type TRequestVerifyCompanyEmailMaterials = {
  email?: TRequestVerifyCompanyEmailParams;
};

export type TRequestVerifyCompanyEmailResponse = unknown;

// FUNCTION

export const requestVerifyCompanyEmail = async (email): Promise<TRequestVerifyCompanyEmailResponse> => {
  const response = await ApiService.get(
    `/companies/api/registerRequestVerifyCompanyEmail/?company_verify_email=${email.email}`,
  );
  return response?.data;
};
