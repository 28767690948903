import { TProjectState } from '@/redux/reducers/project';
import { TPostProjectSuccess } from '@/redux/actions/project';

export const postProjectUpdateState = (state: TProjectState, action: TPostProjectSuccess): TProjectState => {
  const updatedState = {
    ...state,
    postProjectResponse: action.payload.response,
  };

  return updatedState;
};
