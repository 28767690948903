import { createActionCreator } from 'deox';
import {
  TRequestVerifyCompanyEmailMaterials,
  TRequestVerifyCompanyEmailResponse,
} from '@/services/api/auth/request-verify-company-email';
import message from '@/utils/message';

// CONSTANTS

export enum ERequestVerifyCompanyEmailAction {
  REQUEST_VERIFY_COMPANY_EMAIL = 'REQUEST_VERIFY_COMPANY_EMAIL',
  REQUEST_VERIFY_COMPANY_EMAIL_REQUEST = 'REQUEST_VERIFY_COMPANY_EMAIL_REQUEST',
  REQUEST_VERIFY_COMPANY_EMAIL_SUCCESS = 'REQUEST_VERIFY_COMPANY_EMAIL_SUCCESS',
  REQUEST_VERIFY_COMPANY_EMAIL_FAILED = 'REQUEST_VERIFY_COMPANY_EMAIL_FAILED',
}

// TYPES

export type TRequestVerifyCompanyEmailRequest = {
  type: ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_REQUEST;
  payload: {
    materials: TRequestVerifyCompanyEmailMaterials;
    successCallback?: (response: TRequestVerifyCompanyEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestVerifyCompanyEmailSuccess = {
  type: ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_SUCCESS;
  payload: { response: TRequestVerifyCompanyEmailResponse };
};

export type TRequestVerifyCompanyEmailFailed = {
  type: ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_FAILED;
};

// FUNCTION

export const requestVerifyCompanyEmailAction = {
  request: createActionCreator(
    ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TRequestVerifyCompanyEmailMaterials,
        successCallback?: (response: TRequestVerifyCompanyEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestVerifyCompanyEmailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_SUCCESS,
    (resolve) =>
      (response: TRequestVerifyCompanyEmailResponse): TRequestVerifyCompanyEmailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    ERequestVerifyCompanyEmailAction.REQUEST_VERIFY_COMPANY_EMAIL_FAILED,
    (resolve) =>
      (error: any): TRequestVerifyCompanyEmailFailed => {
        message.error(error?.message);
        return resolve({ error });
      },
  ),
};
