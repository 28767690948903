import { TAuthState } from '@/redux/reducers/auth';
import { TGetUserInfoSuccess } from '@/redux/actions/auth';
import { updateUserFinalRole } from '@/services/helpers';

export const getUserInfoUpdateState = (state: TAuthState, action: TGetUserInfoSuccess): TAuthState => {
  const userInfo = updateUserFinalRole(action.payload.response);

  const updatedState = {
    ...state,
    getUserInfoResponse: {
      ...action.payload.response,
      finalRole: userInfo.finalRole,
    },
  } as TAuthState;
  return updatedState;
};
