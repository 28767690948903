import { TEmployeeState } from '@/redux/reducers/employee';
import { TAddListEmployeeSuccess } from '@/redux/actions/employee';

export const addListEmployeeUpdateState = (state: TEmployeeState, action: TAddListEmployeeSuccess): TEmployeeState => {
  const updatedState = {
    ...state,
    addListEmployeeResponse: action.payload.response,
  };
  return updatedState;
};
