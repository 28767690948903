import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { addListEmployeeAction } from '@/redux/actions/employee';
import { addListEmployee, TAddListEmployeeResponse } from '@/services/api/employee';
import { handleAPIError } from '@/utils/handle-api-error';

export function* addListEmployeeSaga(action: ActionType<typeof addListEmployeeAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(addListEmployee, materials);
    const addListEmployeeResponse: TAddListEmployeeResponse = response as TAddListEmployeeResponse;
    yield put(addListEmployeeAction.success(addListEmployeeResponse));
    successCallback?.(addListEmployeeResponse);
    handleAPIError(response, 'Success.addUserSuccess');
  } catch (err) {
    yield put(addListEmployeeAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.addListEmployeeError');
  }
}
