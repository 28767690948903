import ApiService from '@/services/api';

// TYPES
export type TVerifyUserEmailParams = unknown;

export type TVerifyUserEmailMaterials = {
  params?: TVerifyUserEmailParams;
};

export type TVerifyUserEmailResponse = unknown;

// FUNCTION

export const verifyUserEmail = async ({ params }: TVerifyUserEmailMaterials): Promise<TVerifyUserEmailResponse> => {
  const response = await ApiService.post(`/users/api/verify_register_email/`, params);
  return response?.data;
};
