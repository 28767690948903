import { TTermConditionState } from '@/redux/reducers/general';
import { TGetTermConditionSuccess } from '@/redux/actions/general';

export const getTermConditionUpdateState = (
  state: TTermConditionState,
  action: TGetTermConditionSuccess,
): TTermConditionState => {
  const updatedState = {
    ...state,
    getTermConditionResponse: action.payload.response,
  };

  return updatedState;
};
