import { createActionCreator } from 'deox';

import { TGetListProjectMaterials, TGetListProjectResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EGetListProjectAction {
  GET_LIST_PROJECT = 'GET_LIST_PROJECT',
  GET_LIST_PROJECT_REQUEST = 'GET_LIST_PROJECT_REQUEST',
  GET_LIST_PROJECT_SUCCESS = 'GET_LIST_PROJECT_SUCCESS',
  GET_LIST_PROJECT_FAILED = 'GET_LIST_PROJECT_FAILED',
}

// TYPES

export type TGetListProjectRequest = {
  type: EGetListProjectAction.GET_LIST_PROJECT_REQUEST;
  payload: {
    materials: TGetListProjectMaterials;
    successCallback?: (response: TGetListProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetListProjectSuccess = {
  type: EGetListProjectAction.GET_LIST_PROJECT_SUCCESS;
  payload: { response: TGetListProjectResponse };
};

export type TGetListProjectFailed = { type: EGetListProjectAction.GET_LIST_PROJECT_FAILED };

// FUNCTION

export const getListProjectAction = {
  request: createActionCreator(
    EGetListProjectAction.GET_LIST_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TGetListProjectMaterials,
        successCallback?: (response: TGetListProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetListProjectRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetListProjectAction.GET_LIST_PROJECT_SUCCESS,
    (resolve) =>
      (response: TGetListProjectResponse): TGetListProjectSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetListProjectAction.GET_LIST_PROJECT_FAILED,
    (resolve) =>
      (error: any): TGetListProjectFailed => {
        message.error('GET_LIST_PROJECT_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
