import { createAction } from 'deox';
type TUser = {
  userID: number;
  username: string;
  fullName: string;
  email: string;
  companyInfo: any;
  // Add more fields as needed
};

export const setUser = createAction(
  'user/SET_USER',
  (resolve) =>
    (user: TUser): any =>
      resolve({ payload: user }),
);
