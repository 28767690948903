import { createActionCreator } from 'deox';

import { TPostProjectSettingMaterials, TPostProjectSettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';

// CONSTANTS

export enum EPostProjectSettingAction {
  POST_PROJECTSETTING = 'POST_PROJECTSETTING',
  POST_PROJECTSETTING_REQUEST = 'POST_PROJECTSETTING_REQUEST',
  POST_PROJECTSETTING_SUCCESS = 'POST_PROJECTSETTING_SUCCESS',
  POST_PROJECTSETTING_FAILED = 'POST_PROJECTSETTING_FAILED',
}

// TYPES

export type TPostProjectSettingRequest = {
  type: EPostProjectSettingAction.POST_PROJECTSETTING_REQUEST;
  payload: {
    materials: TPostProjectSettingMaterials;
    successCallback?: (response: TPostProjectSettingResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostProjectSettingSuccess = {
  type: EPostProjectSettingAction.POST_PROJECTSETTING_SUCCESS;
  payload: { response: TPostProjectSettingResponse };
};

export type TPostProjectSettingFailed = { type: EPostProjectSettingAction.POST_PROJECTSETTING_FAILED };

// FUNCTION

export const postProjectSettingAction = {
  request: createActionCreator(
    EPostProjectSettingAction.POST_PROJECTSETTING_REQUEST,
    (resolve) =>
      (
        materials: TPostProjectSettingMaterials,
        successCallback?: (response: TPostProjectSettingResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostProjectSettingRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostProjectSettingAction.POST_PROJECTSETTING_SUCCESS,
    (resolve) =>
      (response: TPostProjectSettingResponse): TPostProjectSettingSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostProjectSettingAction.POST_PROJECTSETTING_FAILED,
    (resolve) =>
      (error: any): TPostProjectSettingFailed => {
        message.error('SAVE_PROJECTDETAIL_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
