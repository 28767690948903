import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TProjectExportPdfParams = unknown;

export type TProjectExportPdfResponse = TCommonResponse;

export type TProjectExportPdfMaterials = {
  project_id?: TProjectExportPdfParams; // Correct the property name to "project_id"
};

export const projectExportPdf = async (id): Promise<TProjectExportPdfResponse> => {
  const response = await ApiService.get(`/projects/api/getProjectPDFFile/?project_id=${id.project_id}`);
  return response?.data; // Return the data from the response
};
