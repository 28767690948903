import { createActionCreator } from 'deox';
import { TVerifyUserEmailMaterials, TVerifyUserEmailResponse } from '@/services/api';
import message from '@/utils/message';

// CONSTANTS

export enum EVerifyUserEmailAction {
  VERIFY_USER_EMAIL = 'VERIFY_USER_EMAIL',
  VERIFY_USER_EMAIL_REQUEST = 'VERIFY_USER_EMAIL_REQUEST',
  VERIFY_USER_EMAIL_SUCCESS = 'VERIFY_USER_EMAIL_SUCCESS',
  VERIFY_USER_EMAIL_FAILED = 'VERIFY_USER_EMAIL_FAILED',
}

// TYPES

export type TVerifyUserEmailRequest = {
  type: EVerifyUserEmailAction.VERIFY_USER_EMAIL_REQUEST;
  payload: {
    materials: TVerifyUserEmailMaterials;
    successCallback?: (response: TVerifyUserEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVerifyUserEmailSuccess = {
  type: EVerifyUserEmailAction.VERIFY_USER_EMAIL_SUCCESS;
  payload: { response: TVerifyUserEmailResponse };
};

export type TVerifyUserEmailFailed = { type: EVerifyUserEmailAction.VERIFY_USER_EMAIL_FAILED };

// FUNCTION

export const verifyUserEmailAction = {
  request: createActionCreator(
    EVerifyUserEmailAction.VERIFY_USER_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TVerifyUserEmailMaterials,
        successCallback?: (response: TVerifyUserEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVerifyUserEmailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EVerifyUserEmailAction.VERIFY_USER_EMAIL_SUCCESS,
    (resolve) =>
      (response: TVerifyUserEmailResponse): TVerifyUserEmailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EVerifyUserEmailAction.VERIFY_USER_EMAIL_FAILED,
    (resolve) =>
      (error: any): TVerifyUserEmailFailed => {
        message.error(error?.message);
        return resolve({ error });
      },
  ),
};
