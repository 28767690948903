import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { postProjectAction } from '@/redux/actions/project';
import { postProject, TPostProjectResponse } from '@/services/api/project';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* postProjectSaga(action: ActionType<typeof postProjectAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postProject, materials);
    const postProjectResponse: TPostProjectResponse = response as TPostProjectResponse;
    yield put(postProjectAction.success(postProjectResponse));
    successCallback?.(postProjectResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getListProjectsByUserError');
    yield put(postProjectAction.failure(err));
    failedCallback?.(err);
  }
}
