import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios, { AxiosRequestConfig } from 'axios';

// TYPES

export type TCopyProjectParams = unknown;

export type TCopyProjectResponse = TCommonResponse;

export type TCopyProjectMaterials = {
  arrayList?: TCopyProjectParams;
  company_id?: TCopyProjectParams;
};

export const copyProject = async ({ arrayList, company_id }: TCopyProjectMaterials): Promise<TCopyProjectResponse> => {
  const params = {
    'params': {
      'list_project_ids': arrayList,
      'company_id': company_id,
    },
  };
  const response = await ApiService.post('/projects/api/copyProjectList/', params);
  return response?.data; // Return the data from the response to sagas
};
