import { all, takeLatest } from 'redux-saga/effects';

import { getTermConditionAction } from '@/redux/actions/general';
import { getBackendVersionAction } from '@/redux/actions/general';

import { getTermConditionSaga } from './get-term-condition';
import { getBackendVersionSaga } from './get-backend-version';

export default function* root(): Generator {
  yield all([takeLatest(getTermConditionAction.request.type, getTermConditionSaga)]);
  yield all([takeLatest(getBackendVersionAction.request.type, getBackendVersionSaga)]);
}
