import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';

// TYPES

export type TResendOtpParams = unknown;

export type TResendOtpResponse = TCommonResponse;

export type TResendOtpMaterials = {
  email?: TResendOtpParams; // Correct the property name to "project_id"
};

export const resendOtp = async (email): Promise<TResendOtpResponse> => {
  const response = await ApiService.get(`/users/api/resend_otp/?email=${email.email}`);
  return response?.data; // Return the data from the response
};
