import { createActionCreator } from 'deox';

// CONSTANTS

export enum EGridSelectedAction {
  GRID_SELECTED = 'GRID_SELECTED',
  GRID_SELECTED_REQUEST = 'GRID_SELECTED_REQUEST',
  GRID_SELECTED_SUCCESS = 'GRID_SELECTED_SUCCESS',
  GRID_SELECTED_FAILED = 'GRID_SELECTED_FAILED',
}

// TYPES

export type TGridSelectedRequest = {
  type: EGridSelectedAction.GRID_SELECTED_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TGridSelectedSuccess = {
  type: EGridSelectedAction.GRID_SELECTED_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TGridSelectedFailed = {
  type: EGridSelectedAction.GRID_SELECTED_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const gridSelectedAction = {
  request: createActionCreator(
    EGridSelectedAction.GRID_SELECTED_REQUEST,
    (resolve) =>
      (payload: { GridSelected: any }): TGridSelectedRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EGridSelectedAction.GRID_SELECTED_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TGridSelectedSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EGridSelectedAction.GRID_SELECTED_FAILED,
    (resolve) => (/* Pass your payload data here */): TGridSelectedFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
