import { createActionCreator } from 'deox';

import { TGetBackendVersionResponse } from '@/services/api/general';
import message from '@/utils/message';

// CONSTANTS

export enum EGetBackendVersionAction {
  GET_BACKEND_VERSION = 'GET_BACKEND_VERSION',
  GET_BACKEND_VERSION_REQUEST = 'GET_BACKEND_VERSION_REQUEST',
  GET_BACKEND_VERSION_SUCCESS = 'GET_BACKEND_VERSION_SUCCESS',
  GET_BACKEND_VERSION_FAILED = 'GET_BACKEND_VERSION_FAILED',
}

// TYPES

export type TGetBackendVersionRequest = {
  type: EGetBackendVersionAction.GET_BACKEND_VERSION_REQUEST;
  payload: {
    successCallback?: (response: TGetBackendVersionResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetBackendVersionSuccess = {
  type: EGetBackendVersionAction.GET_BACKEND_VERSION_SUCCESS;
  payload: { response: TGetBackendVersionResponse };
};

export type TGetBackendVersionFailed = { type: EGetBackendVersionAction.GET_BACKEND_VERSION_FAILED };

// FUNCTION

export const getBackendVersionAction = {
  request: createActionCreator(
    EGetBackendVersionAction.GET_BACKEND_VERSION_REQUEST,
    (resolve) =>
      (
        successCallback?: (response: TGetBackendVersionResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetBackendVersionRequest => {
        return resolve({ successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetBackendVersionAction.GET_BACKEND_VERSION_SUCCESS,
    (resolve) =>
      (response: TGetBackendVersionResponse): TGetBackendVersionSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetBackendVersionAction.GET_BACKEND_VERSION_FAILED,
    (resolve) =>
      (error: any): TGetBackendVersionFailed => {
        message.error('GET_BACKEND_VERSION_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
