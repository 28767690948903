import { createActionCreator } from 'deox';

// CONSTANTS

export enum EValidationCheckAction {
  VALIDATION_CHECK = 'VALIDATION_CHECK',
  VALIDATION_CHECK_REQUEST = 'VALIDATION_CHECK_REQUEST',
  VALIDATION_CHECK_SUCCESS = 'VALIDATION_CHECK_SUCCESS',
  VALIDATION_CHECK_FAILED = 'VALIDATION_CHECK_FAILED',
}

// TYPES

export type TValidationCheckRequest = {
  type: EValidationCheckAction.VALIDATION_CHECK_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TValidationCheckSuccess = {
  type: EValidationCheckAction.VALIDATION_CHECK_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TValidationCheckFailed = {
  type: EValidationCheckAction.VALIDATION_CHECK_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const validationCheckAction = {
  request: createActionCreator(
    EValidationCheckAction.VALIDATION_CHECK_REQUEST,
    (resolve) =>
      (payload: { validateCheck: boolean }): TValidationCheckRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EValidationCheckAction.VALIDATION_CHECK_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TValidationCheckSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EValidationCheckAction.VALIDATION_CHECK_FAILED,
    (resolve) => (/* Pass your payload data here */): TValidationCheckFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
