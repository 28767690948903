import { TAuthState } from '@/redux/reducers/auth';
import { TRequestVerifyCompanyEmailSuccess } from '@/redux/actions/auth';

export const requestVerifyCompanyEmailUpdateState = (
  state: TAuthState,
  action: TRequestVerifyCompanyEmailSuccess,
): TAuthState => ({
  ...state,
  requestVerifyCompanyEmailResponse: action.payload.response,
});
