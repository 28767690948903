import { createActionCreator } from 'deox';
import { TUpdateProfileMaterials, TUpdateProfileResponse } from '@/services/api/auth';
import message from '@/utils/message';

// CONSTANTS

export enum EUpdateProfileAction {
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED',
}

// TYPES

export type TUpdateProfileRequest = {
  type: EUpdateProfileAction.UPDATE_PROFILE_REQUEST;
  payload: {
    materials: TUpdateProfileMaterials;
    successCallback?: (response: TUpdateProfileResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpdateProfileSuccess = {
  type: EUpdateProfileAction.UPDATE_PROFILE_SUCCESS;
  payload: { response: TUpdateProfileResponse };
};

export type TUpdateProfileFailed = { type: EUpdateProfileAction.UPDATE_PROFILE_FAILED };

// FUNCTION

export const updateProfileAction = {
  request: createActionCreator(
    EUpdateProfileAction.UPDATE_PROFILE_REQUEST,
    (resolve) =>
      (
        materials: TUpdateProfileMaterials,
        successCallback?: (response: TUpdateProfileResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpdateProfileRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EUpdateProfileAction.UPDATE_PROFILE_SUCCESS,
    (resolve) =>
      (response: TUpdateProfileResponse): TUpdateProfileSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EUpdateProfileAction.UPDATE_PROFILE_FAILED,
    (resolve) =>
      (error: any): TUpdateProfileFailed => {
        // message.error('UPDATE_PROFILE_FAILED Action:', error?.data.status_message);
        return resolve({ error });
      },
  ),
};
