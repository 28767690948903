import { createActionCreator } from 'deox';

// CONSTANTS

export enum EValidationCalcAction {
  VALIDATION_CALC = 'VALIDATION_CALC',
  VALIDATION_CALC_REQUEST = 'VALIDATION_CALC_REQUEST',
  VALIDATION_CALC_SUCCESS = 'VALIDATION_CALC_SUCCESS',
  VALIDATION_CALC_FAILED = 'VALIDATION_CALC_FAILED',
}

// TYPES

export type TValidationCalcRequest = {
  type: EValidationCalcAction.VALIDATION_CALC_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TValidationCalcSuccess = {
  type: EValidationCalcAction.VALIDATION_CALC_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TValidationCalcFailed = {
  type: EValidationCalcAction.VALIDATION_CALC_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const validationCalcAction = {
  request: createActionCreator(
    EValidationCalcAction.VALIDATION_CALC_REQUEST,
    (resolve) =>
      (payload: { validateCalcStatus: boolean }): TValidationCalcRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EValidationCalcAction.VALIDATION_CALC_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TValidationCalcSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EValidationCalcAction.VALIDATION_CALC_FAILED,
    (resolve) => (/* Pass your payload data here */): TValidationCalcFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
