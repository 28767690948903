import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios, { AxiosRequestConfig } from 'axios';

// TYPES

export type TDeleteProjectParams = unknown;

export type TDeleteProjectResponse = TCommonResponse;

export type TDeleteProjectMaterials = {
  arrayList?: TDeleteProjectParams;
  company_id?: TDeleteProjectParams;
};

export const deleteProject = async ({
  arrayList,
  company_id,
}: TDeleteProjectMaterials): Promise<TDeleteProjectResponse> => {
  const params: AxiosRequestConfig = {
    method: 'delete', // Specify the HTTP method
    url: `/projects/api/deleteProjectList/`,
    data: {
      'params': {
        'list_project_ids': arrayList,
        'company_id': company_id,
      },
    },
  };
  const response = await ApiService(params);
  return response?.data; // Return the data from the response
};
