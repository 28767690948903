import { TEmployeeState } from '@/redux/reducers/employee';
import { TUnAssignUsersListSuccess } from '@/redux/actions/employee';

export const unAssignUsersListUpdateState = (
  state: TEmployeeState,
  action: TUnAssignUsersListSuccess,
): TEmployeeState => {
  const updatedState = {
    ...state,
    unAssignUsersListResponse: action.payload.response,
  };
  return updatedState;
};
