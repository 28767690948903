import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { saveProjectDetailAction } from '@/redux/actions/project-detail';
import { saveProjectDetail, TSaveProjectDetailResponse } from '@/services/api/project-detail';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* saveProjectDetailSaga(action: ActionType<typeof saveProjectDetailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(saveProjectDetail, materials);
    const saveProjectDetailResponse: TSaveProjectDetailResponse = response as TSaveProjectDetailResponse;
    yield put(saveProjectDetailAction.success(saveProjectDetailResponse));
    successCallback?.(saveProjectDetailResponse);
  } catch (err) {
    yield put(saveProjectDetailAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateProjectWorkbookError');
  }
}
