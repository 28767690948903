import { createActionCreator } from 'deox';

import { TUpgradeLicenseMaterials, TUpgradeLicenseResponse } from '@/services/api/company';

// CONSTANTS

export enum EUpgradeLicenseAction {
  UPGRADE_LICENSE = 'UPGRADE_LICENSE',
  UPGRADE_LICENSE_REQUEST = 'UPGRADE_LICENSE_REQUEST',
  UPGRADE_LICENSE_SUCCESS = 'UPGRADE_LICENSE_SUCCESS',
  UPGRADE_LICENSE_FAILED = 'UPGRADE_LICENSE_FAILED',
}

// TYPES

export type TUpgradeLicenseRequest = {
  type: EUpgradeLicenseAction.UPGRADE_LICENSE_REQUEST;
  payload: {
    materials: TUpgradeLicenseMaterials;
    successCallback?: (response: TUpgradeLicenseResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUpgradeLicenseSuccess = {
  type: EUpgradeLicenseAction.UPGRADE_LICENSE_SUCCESS;
  payload: { response: TUpgradeLicenseResponse };
};

export type TUpgradeLicenseFailed = { type: EUpgradeLicenseAction.UPGRADE_LICENSE_FAILED };

// FUNCTION

export const upgradeLicenseAction = {
  request: createActionCreator(
    EUpgradeLicenseAction.UPGRADE_LICENSE_REQUEST,
    (resolve) =>
      (
        materials: TUpgradeLicenseMaterials,
        successCallback?: (response: TUpgradeLicenseResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUpgradeLicenseRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUpgradeLicenseAction.UPGRADE_LICENSE_SUCCESS,
    (resolve) =>
      (response: TUpgradeLicenseResponse): TUpgradeLicenseSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUpgradeLicenseAction.UPGRADE_LICENSE_FAILED,
    (resolve) =>
      (error: any): TUpgradeLicenseFailed =>
        resolve({ error }),
  ),
};
