import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { projectExportXmlAction } from '@/redux/actions/project-export';
import { projectExportXml, TProjectExportXmlResponse } from '@/services/api/project-export';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* projectExportXmlSaga(action: ActionType<typeof projectExportXmlAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(projectExportXml, materials);
    const projectExportXmlResponse: TProjectExportXmlResponse = response as TProjectExportXmlResponse;
    yield put(projectExportXmlAction.success(projectExportXmlResponse));
    successCallback?.(projectExportXmlResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getProjectXMLFileError');
    yield put(projectExportXmlAction.failure(err));
    failedCallback?.(err);
  }
}
