import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TPostProjectParams = unknown;

export type TPostProjectResponse = TCommonResponse;

export type TPostProjectMaterials = {
  limit?: TPostProjectParams;
  offset?: TPostProjectParams;
  sort_key?: TPostProjectParams;
  search_str?: TPostProjectParams;
  company_id?: TPostProjectParams;
};
export const postProject = async (params): Promise<TPostProjectResponse> => {
  const response = await ApiService.get(
    `/projects/api/getListProjectsByUser/?limit=${params.limit}&offset=${params.offset}&sort_key=${params.sort_key}&search_str=${params.search_str}&company_id=${params.company_id}`,
  );
  return response?.data; // Return the data from the response
};
