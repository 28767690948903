import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpgradeLicenseParams = unknown;

export type TUpgradeLicenseResponse = TCommonResponse;

export type TUpgradeLicenseMaterials = {
  params?: TUpgradeLicenseParams;
};

export const upgradeLicense = async ({ params }: TUpgradeLicenseMaterials): Promise<TUpgradeLicenseResponse> => {
  const response = await ApiService.post('/companies/api/freeUserUpgradeCreateCompany/', params);
  return response?.data; // Return the data from the response to sagas
};
