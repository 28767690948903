import { createActionCreator } from 'deox';

import { TGetEmployeeInfoMaterials, TGetEmployeeInfoResponse } from '@/services/api/employee';
import message from '@/utils/message';

// CONSTANTS

export enum EGetEmployeeInfoAction {
  GET_EMPLOYEE_INFO = 'GET_EMPLOYEE_INFO',
  GET_EMPLOYEE_INFO_REQUEST = 'GET_EMPLOYEE_INFO_REQUEST',
  GET_EMPLOYEE_INFO_SUCCESS = 'GET_EMPLOYEE_INFO_SUCCESS',
  GET_EMPLOYEE_INFO_FAILED = 'GET_EMPLOYEE_INFO_FAILED',
}

// TYPES

export type TGetEmployeeInfoRequest = {
  type: EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_REQUEST;
  payload: {
    materials: TGetEmployeeInfoMaterials;
    successCallback?: (response: TGetEmployeeInfoResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TGetEmployeeInfoSuccess = {
  type: EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_SUCCESS;
  payload: { response: TGetEmployeeInfoResponse };
};

export type TGetEmployeeInfoFailed = { type: EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_FAILED };

// FUNCTION

export const getEmployeeInfoAction = {
  request: createActionCreator(
    EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_REQUEST,
    (resolve) =>
      (
        materials: TGetEmployeeInfoMaterials,
        successCallback?: (response: TGetEmployeeInfoResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TGetEmployeeInfoRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_SUCCESS,
    (resolve) =>
      (response: TGetEmployeeInfoResponse): TGetEmployeeInfoSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EGetEmployeeInfoAction.GET_EMPLOYEE_INFO_FAILED,
    (resolve) =>
      (error: any): TGetEmployeeInfoFailed => {
        message.error('GET_EMPLOYEE_INFO_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
