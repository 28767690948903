import { createActionCreator } from 'deox';
import { TVerifyCompanyEmailMaterials, TVerifyCompanyEmailResponse } from '@/services/api';
import message from '@/utils/message';

// CONSTANTS

export enum EVerifyCompanyEmailAction {
  VERIFY_COMPANY_EMAIL = 'VERIFY_COMPANY_EMAIL',
  VERIFY_COMPANY_EMAIL_REQUEST = 'VERIFY_COMPANY_EMAIL_REQUEST',
  VERIFY_COMPANY_EMAIL_SUCCESS = 'VERIFY_COMPANY_EMAIL_SUCCESS',
  VERIFY_COMPANY_EMAIL_FAILED = 'VERIFY_COMPANY_EMAIL_FAILED',
}

// TYPES

export type TVerifyCompanyEmailRequest = {
  type: EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_REQUEST;
  payload: {
    materials: TVerifyCompanyEmailMaterials;
    successCallback?: (response: TVerifyCompanyEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TVerifyCompanyEmailSuccess = {
  type: EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_SUCCESS;
  payload: { response: TVerifyCompanyEmailResponse };
};

export type TVerifyCompanyEmailFailed = { type: EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_FAILED };

// FUNCTION

export const verifyCompanyEmailAction = {
  request: createActionCreator(
    EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TVerifyCompanyEmailMaterials,
        successCallback?: (response: TVerifyCompanyEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TVerifyCompanyEmailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_SUCCESS,
    (resolve) =>
      (response: TVerifyCompanyEmailResponse): TVerifyCompanyEmailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EVerifyCompanyEmailAction.VERIFY_COMPANY_EMAIL_FAILED,
    (resolve) =>
      (error: any): TVerifyCompanyEmailFailed => {
        message.error(error?.message);
        return resolve({ error });
      },
  ),
};
