import ApiService from '@/services/api';
import axios from 'axios';

// TYPES

export type TVerifyOtpParams = unknown;
export type TVerifyOtpBody = unknown;

export type TVerifyOtpMaterials = {
  params?: TVerifyOtpParams;
};

export type TVerifyOtpResponse = unknown;

// FUNCTION NOT USED ANYMORE

export const verifyOtp = async ({ params }: TVerifyOtpMaterials): Promise<TVerifyOtpResponse> => {
  const response = await ApiService.post(`/users/api/verify_register_email/`, params);
  return response?.data;
};
