import { createReducer } from 'deox';
import { TGetTermConditionResponse } from '@/services/api/general';
import { getTermConditionAction } from '@/redux/actions/general';
import { getTermConditionUpdateState } from './get-term-condition';

import { TGetBackendVersionResponse } from '@/services/api/general';
import { getBackendVersionAction } from '@/redux/actions/general';
import { getBackendVersionUpdateState } from './get-backend-version';

export type TTermConditionState = {
  getTermConditionResponse?: TGetTermConditionResponse;
  getBackendVersionResponse?: TGetBackendVersionResponse;
};

const initialState: TTermConditionState = {
  getTermConditionResponse: undefined,
  getBackendVersionResponse: undefined,
};

const TermConditionReducer = createReducer(initialState, (handleAction) => [
  handleAction(getTermConditionAction.success, (state, action) => {
    // Call the getTermConditionUpdateState function
    const updatedState = getTermConditionUpdateState(state, action);

    return updatedState;
  }),
]);

export default TermConditionReducer;
