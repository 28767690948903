import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TProjectExportExcelParams = unknown;

export type TProjectExportExcelResponse = TCommonResponse;

export type TProjectExportExcelMaterials = {
  project_id?: TProjectExportExcelParams; // Correct the property name to "project_id"
};

export const projectExportExcel = async (id): Promise<TProjectExportExcelResponse> => {
  try {
    const response = await ApiService.get(`/projects/api/getProjectSettingData/?project_id=${id.project_id}`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error(error.response.data.status_message);
    throw error.response; // Rethrow the error to handle it in your saga
  }
};
