import { createReducer } from 'deox';
import { setDisableDatagridState } from './disable-datagrid';
import { saveDisableDatagridAction } from '@/redux/actions';

export type TEditState = {
  status?: any;
};

const initialState: TEditState = {
  status: false,
};

const SaveReducer = createReducer(initialState, (handleAction) => [
  handleAction(saveDisableDatagridAction.request, (state, action) => {
    const updatedState = setDisableDatagridState(state, action);

    return updatedState;
  }),
]);

export default SaveReducer;
