import ApiService from '@/services/api';

// TYPES
export type TRequestVerifyUserEmailParams = unknown;

export type TRequestVerifyUserEmailMaterials = {
  email?: TRequestVerifyUserEmailParams;
};

export type TRequestVerifyUserEmailResponse = unknown;

// FUNCTION

export const requestVerifyUserEmail = async (email): Promise<TRequestVerifyUserEmailResponse> => {
  const response = await ApiService.get(`/users/api/requestVerifyUserEmail/?email=${email.email}`);
  return response?.data;
};
