import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { verifyCompanyEmailAction } from '@/redux/actions';
import { verifyCompanyEmail, TVerifyCompanyEmailResponse } from '@/services/api';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* verifyCompanyEmailSaga(action: ActionType<typeof verifyCompanyEmailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(verifyCompanyEmail, materials);
    const verifyUserEmailResponse: TVerifyCompanyEmailResponse = response as TVerifyCompanyEmailResponse;
    yield put(verifyCompanyEmailAction.success(verifyUserEmailResponse));
    successCallback?.(verifyUserEmailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.verifyOTPCompanyEmailError');
    yield put(verifyCompanyEmailAction.failure(err));
    failedCallback?.(err);
  }
}
