import { createActionCreator } from 'deox';

import { TPostProjectMaterials, TPostProjectResponse } from '@/services/api/project';
import message from '@/utils/message';

// CONSTANTS

export enum EPostProjectAction {
  POST_PROJECT = 'POST_PROJECT',
  POST_PROJECT_REQUEST = 'POST_PROJECT_REQUEST',
  POST_PROJECT_SUCCESS = 'POST_PROJECT_SUCCESS',
  POST_PROJECT_FAILED = 'POST_PROJECT_FAILED',
}

// TYPES

export type TPostProjectRequest = {
  type: EPostProjectAction.POST_PROJECT_REQUEST;
  payload: {
    materials: TPostProjectMaterials;
    successCallback?: (response: TPostProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostProjectSuccess = {
  type: EPostProjectAction.POST_PROJECT_SUCCESS;
  payload: { response: TPostProjectResponse };
};

export type TPostProjectFailed = { type: EPostProjectAction.POST_PROJECT_FAILED };

// FUNCTION

export const postProjectAction = {
  request: createActionCreator(
    EPostProjectAction.POST_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TPostProjectMaterials,
        successCallback?: (response: TPostProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostProjectRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EPostProjectAction.POST_PROJECT_SUCCESS,
    (resolve) =>
      (response: TPostProjectResponse): TPostProjectSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    EPostProjectAction.POST_PROJECT_FAILED,
    (resolve) =>
      (error: any): TPostProjectFailed => {
        message.error('POST_PROJECT_FAILED Action:', error.data.status_message);

        return resolve({ error });
      },
  ),
};
