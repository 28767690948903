import { TCompanyState } from '@/redux/reducers/company';
import { TVerifyEmailSuccess } from '@/redux/actions/company';

export const verifyEmailUpdateState = (state: TCompanyState, action: TVerifyEmailSuccess): TCompanyState => {
  const updatedState = {
    ...state,
    verifyEmailResponse: action.payload.response,
  };
  return updatedState;
};
