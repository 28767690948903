import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { postProjectDetailAction } from '@/redux/actions/project-detail';
import { postProjectDetail, TPostProjectDetailResponse } from '@/services/api/project-detail';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* postProjectDetailSaga(action: ActionType<typeof postProjectDetailAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postProjectDetail, materials);
    const postProjectDetailResponse: TPostProjectDetailResponse = response as TPostProjectDetailResponse;
    yield put(postProjectDetailAction.success(postProjectDetailResponse));
    successCallback?.(postProjectDetailResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(postProjectDetailAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.calculateProjectError');
  }
}
