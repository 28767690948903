import { TProjectSettingState } from '@/redux/reducers/project-setting';
import { TPostCompanySettingSuccess } from '@/redux/actions/project-setting';

export const postCompanySettingUpdateState = (
  state: TProjectSettingState,
  action: TPostCompanySettingSuccess,
): TProjectSettingState => {
  const updatedState = {
    ...state,
    postCompanySettingResponse: action.payload.response,
  };
  return updatedState;
};
