import { createActionCreator } from 'deox';

import { TAddListEmployeeMaterials, TAddListEmployeeResponse } from '@/services/api/employee';

// CONSTANTS

export enum EAddListEmployeeAction {
  ADD_LIST_EMPLOYEE = 'ADD_LIST_EMPLOYEE',
  ADD_LIST_EMPLOYEE_REQUEST = 'ADD_LIST_EMPLOYEE_REQUEST',
  ADD_LIST_EMPLOYEE_SUCCESS = 'ADD_LIST_EMPLOYEE_SUCCESS',
  ADD_LIST_EMPLOYEE_FAILED = 'ADD_LIST_EMPLOYEE_FAILED',
}

// TYPES

export type TAddListEmployeeRequest = {
  type: EAddListEmployeeAction.ADD_LIST_EMPLOYEE_REQUEST;
  payload: {
    materials: TAddListEmployeeMaterials;
    successCallback?: (response: TAddListEmployeeResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAddListEmployeeSuccess = {
  type: EAddListEmployeeAction.ADD_LIST_EMPLOYEE_SUCCESS;
  payload: { response: TAddListEmployeeResponse };
};

export type TAddListEmployeeFailed = { type: EAddListEmployeeAction.ADD_LIST_EMPLOYEE_FAILED };

// FUNCTION

export const addListEmployeeAction = {
  request: createActionCreator(
    EAddListEmployeeAction.ADD_LIST_EMPLOYEE_REQUEST,
    (resolve) =>
      (
        materials: TAddListEmployeeMaterials,
        successCallback?: (response: TAddListEmployeeResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAddListEmployeeRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAddListEmployeeAction.ADD_LIST_EMPLOYEE_SUCCESS,
    (resolve) =>
      (response: TAddListEmployeeResponse): TAddListEmployeeSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAddListEmployeeAction.ADD_LIST_EMPLOYEE_FAILED,
    (resolve) =>
      (error: any): TAddListEmployeeFailed =>
        resolve({ error }),
  ),
};
