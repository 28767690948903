import { createActionCreator } from 'deox';
import {
  TRequestVerifyUserEmailMaterials,
  TRequestVerifyUserEmailResponse,
} from '@/services/api/auth/request-verify-user-email';
import message from '@/utils/message';

// CONSTANTS

export enum ERequestVerifyUserEmailAction {
  REQUEST_VERIFY_USER_EMAIL = 'REQUEST_VERIFY_USER_EMAIL',
  REQUEST_VERIFY_USER_EMAIL_REQUEST = 'REQUEST_VERIFY_USER_EMAIL_REQUEST',
  REQUEST_VERIFY_USER_EMAIL_SUCCESS = 'REQUEST_VERIFY_USER_EMAIL_SUCCESS',
  REQUEST_VERIFY_USER_EMAIL_FAILED = 'REQUEST_VERIFY_USER_EMAIL_FAILED',
}

// TYPES

export type TRequestVerifyUserEmailRequest = {
  type: ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_REQUEST;
  payload: {
    materials: TRequestVerifyUserEmailMaterials;
    successCallback?: (response: TRequestVerifyUserEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRequestVerifyUserEmailSuccess = {
  type: ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_SUCCESS;
  payload: { response: TRequestVerifyUserEmailResponse };
};

export type TRequestVerifyUserEmailFailed = { type: ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_FAILED };

// FUNCTION

export const requestVerifyUserEmailAction = {
  request: createActionCreator(
    ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TRequestVerifyUserEmailMaterials,
        successCallback?: (response: TRequestVerifyUserEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRequestVerifyUserEmailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_SUCCESS,
    (resolve) =>
      (response: TRequestVerifyUserEmailResponse): TRequestVerifyUserEmailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    ERequestVerifyUserEmailAction.REQUEST_VERIFY_USER_EMAIL_FAILED,
    (resolve) =>
      (error: any): TRequestVerifyUserEmailFailed => {
        message.error(error?.message);
        return resolve({ error });
      },
  ),
};
