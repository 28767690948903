import { all, takeLatest } from 'redux-saga/effects';

import {
  postProjectDetailAction,
  getProjectDetailAction,
  saveProjectDetailAction,
} from '@/redux/actions/project-detail';

import { postProjectDetailSaga } from './post-project-detail';
import { getProjectDetailSaga } from './get-project-detail';
import { saveProjectDetailSaga } from './save-project-detail';

export default function* root(): Generator {
  yield all([takeLatest(postProjectDetailAction.request.type, postProjectDetailSaga)]);
  yield all([takeLatest(getProjectDetailAction.request.type, getProjectDetailSaga)]);
  yield all([takeLatest(saveProjectDetailAction.request.type, saveProjectDetailSaga)]);
}
