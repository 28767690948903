import { createActionCreator } from 'deox';

import { TResendOtpMaterials, TResendOtpResponse } from '@/services/api/auth';
import message from '@/utils/message';

// CONSTANTS

export enum EResendOtpAction {
  RESEND_OTP = 'RESEND_OTP',
  RESEND_OTP_REQUEST = 'RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILED = 'RESEND_OTP_FAILED',
}

// TYPES

export type TResendOtpRequest = {
  type: EResendOtpAction.RESEND_OTP_REQUEST;
  payload: {
    materials: TResendOtpMaterials;
    successCallback?: (response: TResendOtpResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TResendOtpSuccess = {
  type: EResendOtpAction.RESEND_OTP_SUCCESS;
  payload: { response: TResendOtpResponse };
};

export type TResendOtpFailed = { type: EResendOtpAction.RESEND_OTP_FAILED };

// FUNCTION

export const resendOtpAction = {
  request: createActionCreator(
    EResendOtpAction.RESEND_OTP_REQUEST,
    (resolve) =>
      (
        materials: TResendOtpMaterials,
        successCallback?: (response: TResendOtpResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TResendOtpRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    EResendOtpAction.RESEND_OTP_SUCCESS,
    (resolve) =>
      (response: TResendOtpResponse): TResendOtpSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(EResendOtpAction.RESEND_OTP_FAILED, (resolve) => (error: any): TResendOtpFailed => {
    message.error('RESEND_OTP_FAILED Action:', error?.data.status_message);

    return resolve({ error });
  }),
};
