import { createActionCreator } from 'deox';

// CONSTANTS

export enum EDataProjectAction {
  DATA_PROJECT = 'DATA_PROJECT',
  DATA_PROJECT_REQUEST = 'DATA_PROJECT_REQUEST',
  DATA_PROJECT_SUCCESS = 'DATA_PROJECT_SUCCESS',
  DATA_PROJECT_FAILED = 'DATA_PROJECT_FAILED',
}

// TYPES

export type TDataProjectRequest = {
  type: EDataProjectAction.DATA_PROJECT_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TDataProjectSuccess = {
  type: EDataProjectAction.DATA_PROJECT_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TDataProjectFailed = {
  type: EDataProjectAction.DATA_PROJECT_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const DataProjectAction = {
  request: createActionCreator(
    EDataProjectAction.DATA_PROJECT_REQUEST,
    (resolve) =>
      (payload: { data: any }): TDataProjectRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    EDataProjectAction.DATA_PROJECT_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TDataProjectSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    EDataProjectAction.DATA_PROJECT_FAILED,
    (resolve) => (/* Pass your payload data here */): TDataProjectFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
