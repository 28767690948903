import { createReducer } from 'deox';
import { TPostProjectResponse } from '@/services/api/project';
import { TCreateProjectResponse } from '@/services/api/project';
import { TDeleteProjectResponse } from '@/services/api/project';
import { TCopyProjectResponse } from '@/services/api/project';
import { TRenameProjectResponse } from '@/services/api/project';
import { postProjectUpdateState } from './post-project';
import { createProjectUpdateState } from './create-project';
import { deleteProjectUpdateState } from './delete-project';
import { copyProjectUpdateState } from './copy-project';
import { renameProjectUpdateState } from './rename-project';
import {
  postProjectAction,
  createProjectAction,
  deleteProjectAction,
  copyProjectAction,
  renameProjectAction,
} from '@/redux/actions/project';

export type TProjectState = {
  postProjectResponse?: TPostProjectResponse;
  createProjectResponse?: TCreateProjectResponse;
  deleteProjectResponse?: TDeleteProjectResponse;
  copyProjectResponse?: TCopyProjectResponse;
  renameProjectResponse?: TRenameProjectResponse;
};

const initialState: TProjectState = {
  postProjectResponse: undefined,
  createProjectResponse: undefined,
  deleteProjectResponse: undefined,
  copyProjectResponse: undefined,
  renameProjectResponse: undefined,
};

const ProjectReducer = createReducer(initialState, (handleAction) => [
  handleAction(postProjectAction.success, (state, action) => {
    // Call the postProjectUpdateState function
    const updatedState = postProjectUpdateState(state, action);

    return updatedState;
  }),
  handleAction(createProjectAction.success, (state, action) => {
    // Call the createProjectUpdateState function
    const updatedState = createProjectUpdateState(state, action);
    return updatedState;
  }),
  handleAction(deleteProjectAction.success, (state, action) => {
    // Call the deleteProjectAction function
    const updatedState = deleteProjectUpdateState(state, action);
    return updatedState;
  }),
  handleAction(copyProjectAction.success, (state, action) => {
    // Call the copyProjectAction function
    const updatedState = copyProjectUpdateState(state, action);
    return updatedState;
  }),
  handleAction(renameProjectAction.success, (state, action) => {
    // Call the renameProjectAction function
    const updatedState = renameProjectUpdateState(state, action);
    return updatedState;
  }),
]);

export default ProjectReducer;
