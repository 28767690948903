import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getProjectSettingAction } from '@/redux/actions/project-setting';
import { getProjectSetting, TGetProjectSettingResponse } from '@/services/api/project-setting';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* getProjectSettingSaga(action: ActionType<typeof getProjectSettingAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getProjectSetting, materials);
    const getProjectSettingResponse: TGetProjectSettingResponse = response as TGetProjectSettingResponse;
    yield put(getProjectSettingAction.success(getProjectSettingResponse));
    successCallback?.(getProjectSettingResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getProjectSettingDataError');
    yield put(getProjectSettingAction.failure(err));
    failedCallback?.(err);
  }
}
