import { createActionCreator } from 'deox';

import { TSendMailMaterials, TSendMailResponse } from '@/services/api/auth';
import message from '@/utils/message';

// CONSTANTS

export enum ESendMailAction {
  SENDMAIL = 'SENDMAIL',
  SENDMAIL_REQUEST = 'SENDMAIL_REQUEST',
  SENDMAIL_SUCCESS = 'SENDMAIL_SUCCESS',
  SENDMAIL_FAILED = 'SENDMAIL_FAILED',
}

// TYPES

export type TSendMailRequest = {
  type: ESendMailAction.SENDMAIL_REQUEST;
  payload: {
    materials: TSendMailMaterials;
    successCallback?: (response: TSendMailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSendMailSuccess = {
  type: ESendMailAction.SENDMAIL_SUCCESS;
  payload: { response: TSendMailResponse };
};

export type TSendMailFailed = { type: ESendMailAction.SENDMAIL_FAILED };

// FUNCTION

export const sendMailAction = {
  request: createActionCreator(
    ESendMailAction.SENDMAIL_REQUEST,
    (resolve) =>
      (
        materials: TSendMailMaterials,
        successCallback?: (response: TSendMailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSendMailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ESendMailAction.SENDMAIL_SUCCESS,
    (resolve) =>
      (response: TSendMailResponse): TSendMailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(ESendMailAction.SENDMAIL_FAILED, (resolve) => (error: any): TSendMailFailed => {
    message.error('SENDMAIL_FAILED Action:', error?.data.status_message);

    return resolve({ error });
  }),
};
