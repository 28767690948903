import { message as messageAntd } from 'antd';

const message = {
  error: (...args: any[]): void => {
    console.log('message args: ', args);
    const messageContent = args
      .filter((arg) => arg !== undefined)
      .map((arg) => arg.toString())
      .join(' ');

    if (!messageContent.includes('Ungültiges Zugriffstoken')) {
      messageAntd.error(args);
    }
  },
  success: messageAntd.success,
  warning: messageAntd.warning,
  info: messageAntd.info,
};

export default message;
