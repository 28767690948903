import { createActionCreator } from 'deox';

// CONSTANTS

export enum ECellActivateAction {
  CELL_ACTIVATE = 'CELL_ACTIVATE',
  CELL_ACTIVATE_REQUEST = 'CELL_ACTIVATE_REQUEST',
  CELL_ACTIVATE_SUCCESS = 'CELL_ACTIVATE_SUCCESS',
  CELL_ACTIVATE_FAILED = 'CELL_ACTIVATE_FAILED',
}

// TYPES

export type TCellActivateRequest = {
  type: ECellActivateAction.CELL_ACTIVATE_REQUEST;
  payload: {
    // Define your payload here
  };
};

export type TCellActivateSuccess = {
  type: ECellActivateAction.CELL_ACTIVATE_SUCCESS;
  payload: {
    // Define your payload here
  };
};

export type TCellActivateFailed = {
  type: ECellActivateAction.CELL_ACTIVATE_FAILED;
  payload: {
    // Define your payload here
  };
};

// FUNCTION

export const cellActivateAction = {
  request: createActionCreator(
    ECellActivateAction.CELL_ACTIVATE_REQUEST,
    (resolve) =>
      (payload: { CellActivate: any }): TCellActivateRequest => {
        return resolve(payload);
      },
  ),
  success: createActionCreator(
    ECellActivateAction.CELL_ACTIVATE_SUCCESS,
    (resolve) => (/* Pass your payload data here */): TCellActivateSuccess => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
  failure: createActionCreator(
    ECellActivateAction.CELL_ACTIVATE_FAILED,
    (resolve) => (/* Pass your payload data here */): TCellActivateFailed => {
      return resolve({
        /* Pass your payload data here */
      });
    },
  ),
};
