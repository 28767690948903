import { createReducer } from 'deox';
import { TProjectExportXmlResponse } from '@/services/api/project-export';
import { projectExportXmlAction } from '@/redux/actions/project-export';
import { projectExportXmlUpdateState } from './project-export-xml';

import { TProjectExportExcelResponse } from '@/services/api/project-export';
import { projectExportExcelAction } from '@/redux/actions/project-export';
import { projectExportExcelUpdateState } from './project-export-excel';

import { TProjectExportPdfResponse } from '@/services/api/project-export';
import { projectExportPdfAction } from '@/redux/actions/project-export';
import { projectExportPdfUpdateState } from './project-export-pdf';

export type TProjectExportState = {
  projectExportXmlResponse?: TProjectExportXmlResponse;
  projectExportExcelResponse?: TProjectExportExcelResponse;
  projectExportPdfResponse?: TProjectExportPdfResponse;
};

const initialState: TProjectExportState = {
  projectExportXmlResponse: undefined,
  projectExportExcelResponse: undefined,
  projectExportPdfResponse: undefined,
};

const ProjectExportReducer = createReducer(initialState, (handleAction) => [
  handleAction(projectExportXmlAction.success, (state, action) => {
    // Call the getProjectSettingUpdateState function
    const updatedState = projectExportXmlUpdateState(state, action);

    return updatedState;
  }),
  handleAction(projectExportExcelAction.success, (state, action) => {
    // Call the getProjectSettingUpdateState function
    const updatedState = projectExportExcelUpdateState(state, action);

    return updatedState;
  }),
  handleAction(projectExportPdfAction.success, (state, action) => {
    // Call the getProjectSettingUpdateState function
    const updatedState = projectExportPdfUpdateState(state, action);

    return updatedState;
  }),
]);

export default ProjectExportReducer;
