import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { updateUserAction } from '@/redux/actions/auth';
import { updateUser, TUpdateUserResponse } from '@/services/api/auth';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* updateUserSaga(action: ActionType<typeof updateUserAction.request>): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(updateUser, materials);
    const updateUserResponse: TUpdateUserResponse = response as TUpdateUserResponse;
    yield put(updateUserAction.success(updateUserResponse));
    successCallback?.(updateUserResponse);
    handleAPIError(response, 'Success.updateSuccess');
  } catch (err) {
    yield put(updateUserAction.failure(err));
    failedCallback?.(err);
    handleAPIError(err, 'Error.updateUserInfoError');
  }
}
