import { createReducer } from 'deox';
import { setDataProjectState } from './data-project';
import { DataProjectAction, TDataProjectRequest } from '@/redux/actions';

export type TEditState = {
  DataProjectResponse?: TDataProjectRequest;
};

const initialState: TEditState = {
  DataProjectResponse: undefined,
};

const DataProjectReducer = createReducer(initialState, (handleAction) => [
  handleAction(DataProjectAction.request, (state, action) => {
    const updatedState = setDataProjectState(state, action);

    return updatedState;
  }),
]);

export default DataProjectReducer;
