import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { requestVerifyCompanyEmailAction } from '@/redux/actions/auth/request-verify-company-email';
import { requestVerifyCompanyEmail, TRequestVerifyCompanyEmailResponse } from '@/services/api';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* requestVerifyCompanyEmailSaga(
  action: ActionType<typeof requestVerifyCompanyEmailAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(requestVerifyCompanyEmail, materials);
    const requestVerifyCompanyEmailResponse: TRequestVerifyCompanyEmailResponse =
      response as TRequestVerifyCompanyEmailResponse;
    yield put(requestVerifyCompanyEmailAction.success(requestVerifyCompanyEmailResponse));
    successCallback?.(requestVerifyCompanyEmailResponse);
  } catch (err) {
    handleAPIError(err, 'Error.requestVerifyCompanyEmailError');
    yield put(requestVerifyCompanyEmailAction.failure(err));
    failedCallback?.(err);
  }
}
