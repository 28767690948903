import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TAssignUsersListParams = unknown;

export type TAssignUsersListResponse = TCommonResponse;

export type TAssignUsersListMaterials = {
  arrayList?: TAssignUsersListParams;
  company_id?: TAssignUsersListParams;
  project_id?: TAssignUsersListParams;
};

export const assignUsersList = async ({
  arrayList,
  company_id,
  project_id,
}: TAssignUsersListMaterials): Promise<TAssignUsersListResponse> => {
  const params = {
    'params': {
      'list_employees': arrayList,
      'company_id': company_id,
      'project_id': project_id,
    },
  };
  const response = await ApiService.post('/companies/api/assignEmployeesList/', params);
  return response?.data; // Return the data from the response to sagas
};
