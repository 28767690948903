import { TProjectState } from '@/redux/reducers/project';
import { TDeleteProjectSuccess } from '@/redux/actions/project';

export const deleteProjectUpdateState = (state: TProjectState, action: TDeleteProjectSuccess): TProjectState => {
  const updatedState = {
    ...state,
    deleteProjectResponse: action.payload.response,
  };
  return updatedState;
};
