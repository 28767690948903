import { createReducer } from 'deox';
import { TGetListEmployeeInsideResponse } from '@/services/api/employee';
import { getListEmployeeInsideAction } from '@/redux/actions/employee';
import { getListEmployeeInsideUpdateState } from './get-list-employee-inside';

import { TGetListEmployeeResponse } from '@/services/api/employee';
import { getListEmployeeAction } from '@/redux/actions/employee';
import { getListEmployeeUpdateState } from './get-list-employee';

import { TAddListEmployeeResponse } from '@/services/api/employee';
import { addListEmployeeAction } from '@/redux/actions/employee';
import { addListEmployeeUpdateState } from './add-list-employee';

import { TGetListProjectResponse } from '@/services/api/employee';
import { getListProjectAction } from '@/redux/actions/employee';
import { getListProjectUpdateState } from './get-list-project';

import { TDeleteListEmployeeResponse } from '@/services/api/employee';
import { deleteListEmployeeAction } from '@/redux/actions/employee';
import { deleteListEmployeeUpdateState } from './delete-list-employee';

import { TAssignProjectListResponse } from '@/services/api/employee';
import { assignProjectListAction } from '@/redux/actions/employee';
import { assignProjectListUpdateState } from './assign-project-list';

import { TAssignUsersListResponse } from '@/services/api/employee';
import { assignUsersListAction } from '@/redux/actions/employee';
import { assignUsersListUpdateState } from './assign-users-list';

import { TUnAssignUsersListResponse } from '@/services/api/employee';
import { unAssignUsersListAction } from '@/redux/actions/employee';
import { unAssignUsersListUpdateState } from './unassign-users-list';

import { TGetEmployeeInfoResponse } from '@/services/api/employee';
import { getEmployeeInfoAction } from '@/redux/actions/employee';
import { getEmployeeInfoUpdateState } from './get-employee-info';

import { TUpdateProfileEmployeeResponse } from '@/services/api/employee';
import { updateProfileEmployeeAction } from '@/redux/actions/employee';
import { updateProfileEmployeeUpdateState } from './update-profile-employee';

export type TEmployeeState = {
  getListEmployeeInsideResponse?: TGetListEmployeeInsideResponse;
  getListEmployeeResponse?: TGetListEmployeeResponse;
  assignUsersListResponse?: TAssignUsersListResponse;
  unAssignUsersListResponse?: TUnAssignUsersListResponse;
  addListEmployeeResponse?: TAddListEmployeeResponse;
  deleteListEmployeeResponse?: TDeleteListEmployeeResponse;
  getListProjectResponse?: TGetListProjectResponse;
  assignProjectListResponse?: TAssignProjectListResponse;
  getEmployeeInfoResponse?: TGetEmployeeInfoResponse;
  updateProfileEmployeeResponse?: TUpdateProfileEmployeeResponse;
};

const initialState: TEmployeeState = {
  getListEmployeeInsideResponse: undefined,
  getListEmployeeResponse: undefined,
  assignUsersListResponse: undefined,
  unAssignUsersListResponse: undefined,
  addListEmployeeResponse: undefined,
  deleteListEmployeeResponse: undefined,
  getListProjectResponse: undefined,
  assignProjectListResponse: undefined,
  getEmployeeInfoResponse: undefined,
  updateProfileEmployeeResponse: undefined,
};

const EmployeeReducer = createReducer(initialState, (handleAction) => [
  handleAction(getListEmployeeInsideAction.success, (state, action) => {
    const updatedState = getListEmployeeInsideUpdateState(state, action);

    return updatedState;
  }),

  handleAction(updateProfileEmployeeAction.success, (state, action) => {
    const updatedState = updateProfileEmployeeUpdateState(state, action);

    return updatedState;
  }),

  handleAction(getEmployeeInfoAction.success, (state, action) => {
    const updatedState = getEmployeeInfoUpdateState(state, action);

    return updatedState;
  }),

  handleAction(getListEmployeeAction.success, (state, action) => {
    const updatedState = getListEmployeeUpdateState(state, action);

    return updatedState;
  }),

  handleAction(assignUsersListAction.success, (state, action) => {
    const updatedState = assignUsersListUpdateState(state, action);

    return updatedState;
  }),

  handleAction(unAssignUsersListAction.success, (state, action) => {
    const updatedState = unAssignUsersListUpdateState(state, action);

    return updatedState;
  }),

  handleAction(addListEmployeeAction.success, (state, action) => {
    const updatedState = addListEmployeeUpdateState(state, action);

    return updatedState;
  }),

  handleAction(deleteListEmployeeAction.success, (state, action) => {
    const updatedState = deleteListEmployeeUpdateState(state, action);

    return updatedState;
  }),

  handleAction(getListProjectAction.success, (state, action) => {
    const updatedState = getListProjectUpdateState(state, action);

    return updatedState;
  }),

  handleAction(assignProjectListAction.success, (state, action) => {
    const updatedState = assignProjectListUpdateState(state, action);

    return updatedState;
  }),
]);

export default EmployeeReducer;
