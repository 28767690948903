import { createActionCreator } from 'deox';

import { TUnAssignUsersListMaterials, TUnAssignUsersListResponse } from '@/services/api/employee';

// CONSTANTS

export enum EUnAssignUsersListAction {
  UNASSIGN_USERS_LIST = 'UNASSIGN_USERS_LIST',
  UNASSIGN_USERS_LIST_REQUEST = 'UNASSIGN_USERS_LIST_REQUEST',
  UNASSIGN_USERS_LIST_SUCCESS = 'UNASSIGN_USERS_LIST_SUCCESS',
  UNASSIGN_USERS_LIST_FAILED = 'UNASSIGN_USERS_LIST_FAILED',
}

// TYPES

export type TUnAssignUsersListRequest = {
  type: EUnAssignUsersListAction.UNASSIGN_USERS_LIST_REQUEST;
  payload: {
    materials: TUnAssignUsersListMaterials;
    successCallback?: (response: TUnAssignUsersListResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TUnAssignUsersListSuccess = {
  type: EUnAssignUsersListAction.UNASSIGN_USERS_LIST_SUCCESS;
  payload: { response: TUnAssignUsersListResponse };
};

export type TUnAssignUsersListFailed = { type: EUnAssignUsersListAction.UNASSIGN_USERS_LIST_FAILED };

// FUNCTION

export const unAssignUsersListAction = {
  request: createActionCreator(
    EUnAssignUsersListAction.UNASSIGN_USERS_LIST_REQUEST,
    (resolve) =>
      (
        materials: TUnAssignUsersListMaterials,
        successCallback?: (response: TUnAssignUsersListResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TUnAssignUsersListRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EUnAssignUsersListAction.UNASSIGN_USERS_LIST_SUCCESS,
    (resolve) =>
      (response: TUnAssignUsersListResponse): TUnAssignUsersListSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EUnAssignUsersListAction.UNASSIGN_USERS_LIST_FAILED,
    (resolve) =>
      (error: any): TUnAssignUsersListFailed =>
        resolve({ error }),
  ),
};
