import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUpdateProfileParams = unknown;

export type TUpdateProfileMaterials = {
  dataSubmit?: any;
};

export type TUpdateProfileResponse = TCommonResponse;

export const updateProfile = async (dataSubmit: TUpdateProfileMaterials): Promise<TUpdateProfileResponse> => {
  const data = await dataSubmit;
  const response = await ApiService.post(`/users/api/updateCompanyInfo/`, data);
  return response?.data;
};
