import ApiService from '@/services/api';

// TYPES
export type TVerifyCompanyEmailParams = unknown;

export type TVerifyCompanyEmailMaterials = {
  params?: TVerifyCompanyEmailParams;
};

export type TVerifyCompanyEmailResponse = unknown;

// FUNCTION

export const verifyCompanyEmail = async ({
  params,
}: TVerifyCompanyEmailMaterials): Promise<TVerifyCompanyEmailResponse> => {
  const response = await ApiService.post(`/companies/api/registerVerifyCompanyEmail/`, params);
  return response?.data;
};
