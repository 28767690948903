import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TUnAssignUsersListParams = unknown;

export type TUnAssignUsersListResponse = TCommonResponse;

export type TUnAssignUsersListMaterials = {
  arrayList?: TUnAssignUsersListParams;
  company_id?: TUnAssignUsersListParams;
  project_id?: TUnAssignUsersListParams;
};

export const unAssignUsersList = async ({
  arrayList,
  company_id,
  project_id,
}: TUnAssignUsersListMaterials): Promise<TUnAssignUsersListResponse> => {
  const params = {
    'params': {
      'list_employees': arrayList,
      'company_id': company_id,
      'project_id': project_id,
    },
  };
  const response = await ApiService.post('/companies/api/unassignEmployeesList/', params);
  return response?.data; // Return the data from the response to sagas
};
