import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TPostProjectDetailParams = unknown;

export type TPostProjectDetailMaterials = {
  params: any;
  dataSubmit?: any;
};

export type TPostProjectDetailResponse = TCommonResponse;

export const postProjectDetail = async (
  dataSubmit: TPostProjectDetailMaterials,
): Promise<TPostProjectDetailResponse> => {
  const id = await {
    'params': {
      'project_id': dataSubmit.params.project_id?.toString(),
    },
  };
  const response = await ApiService.post(`/projects/api/calculateProject/`, id);
  return response?.data; // Return the data from the response
};
