import { createActionCreator } from 'deox';

import { TRenameProjectMaterials, TRenameProjectResponse } from '@/services/api/project/rename-project';

// CONSTANTS

export enum ERenameProjectAction {
  RENAME_PROJECT = 'RENAME_PROJECT',
  RENAME_PROJECT_REQUEST = 'RENAME_PROJECT_REQUEST',
  RENAME_PROJECT_SUCCESS = 'RENAME_PROJECT_SUCCESS',
  RENAME_PROJECT_FAILED = 'RENAME_PROJECT_FAILED',
}

// TYPES

export type TRenameProjectRequest = {
  type: ERenameProjectAction.RENAME_PROJECT_REQUEST;
  payload: {
    materials: TRenameProjectMaterials;
    successCallback?: (response: TRenameProjectResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TRenameProjectSuccess = {
  type: ERenameProjectAction.RENAME_PROJECT_SUCCESS;
  payload: { response: TRenameProjectResponse };
};

export type TRenameProjectFailed = { type: ERenameProjectAction.RENAME_PROJECT_FAILED };

// FUNCTION

export const renameProjectAction = {
  request: createActionCreator(
    ERenameProjectAction.RENAME_PROJECT_REQUEST,
    (resolve) =>
      (
        materials: TRenameProjectMaterials,
        successCallback?: (response: TRenameProjectResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TRenameProjectRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ERenameProjectAction.RENAME_PROJECT_SUCCESS,
    (resolve) =>
      (response: TRenameProjectResponse): TRenameProjectSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(
    ERenameProjectAction.RENAME_PROJECT_FAILED,
    (resolve) =>
      (error: any): TRenameProjectFailed => {
        return resolve({ error });
      },
  ),
};
