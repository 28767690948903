import { TProjectState } from '@/redux/reducers/project';
import { TRenameProjectSuccess } from '@/redux/actions/project';

export const renameProjectUpdateState = (state: TProjectState, action: TRenameProjectSuccess): TProjectState => {
  const updatedState = {
    ...state,
    renameProjectResponse: action.payload.response,
  };
  return updatedState;
};
