import { TProjectState } from '@/redux/reducers/project';
import { TCopyProjectSuccess } from '@/redux/actions/project';

export const copyProjectUpdateState = (state: TProjectState, action: TCopyProjectSuccess): TProjectState => {
  const updatedState = {
    ...state,
    copyProjectResponse: action.payload.response,
  };
  return updatedState;
};
