import React from 'react';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import { navigate } from '@reach/router';

import Icon from '@/components/Icon';
import { TButtonProps } from './Button.types';

import './Button.scss';

const Button: React.FC<TButtonProps> = ({
  className,
  id,
  size,
  iconName,
  shadow = true,
  iconColor,
  children,
  type,
  htmlType,
  title,
  danger,
  reverse,
  link,
  disabled,
  loading,
  onClick,
}) => {
  const handleClickButton = (e): void => {
    if (link) navigate(link);
    else onClick?.(e);
  };
  return (
    <div className={classNames('Button', className, { shadow, 'only-icon': !title && iconName, reverse })} id={id}>
      <AntdButton
        size={size}
        type={type}
        htmlType={htmlType}
        onClick={handleClickButton}
        danger={danger}
        disabled={disabled}
        loading={loading}
      >
        {iconName}
        <span className="Button-title">{title}</span>
      </AntdButton>
    </div>
  );
};

export default Button;
