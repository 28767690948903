import { TProjectExportState } from '@/redux/reducers/project-export';
import { TProjectExportPdfSuccess } from '@/redux/actions/project-export';

export const projectExportPdfUpdateState = (
  state: TProjectExportState,
  action: TProjectExportPdfSuccess,
): TProjectExportState => {
  const updatedState = {
    ...state,
    projectExportPdfResponse: action.payload.response,
  };

  return updatedState;
};
