import { TProjectSettingState } from '@/redux/reducers/project-setting';
import { TGetProjectSettingSuccess } from '@/redux/actions/project-setting';

export const getProjectSettingUpdateState = (
  state: TProjectSettingState,
  action: TGetProjectSettingSuccess,
): TProjectSettingState => {
  const updatedState = {
    ...state,
    getProjectSettingResponse: action.payload.response,
  };

  return updatedState;
};
