import { createActionCreator } from 'deox';

import { TSendEmailMaterials, TSendEmailResponse } from '@/services/api/company';
import message from '@/utils/message';

// CONSTANTS

export enum ESendEmailAction {
  SEND_EMAIL = 'SEND_EMAIL',
  SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST',
  SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS',
  SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED',
}

// TYPES

export type TSendEmailRequest = {
  type: ESendEmailAction.SEND_EMAIL_REQUEST;
  payload: {
    materials: TSendEmailMaterials;
    successCallback?: (response: TSendEmailResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TSendEmailSuccess = {
  type: ESendEmailAction.SEND_EMAIL_SUCCESS;
  payload: { response: TSendEmailResponse };
};

export type TSendEmailFailed = { type: ESendEmailAction.SEND_EMAIL_FAILED };

// FUNCTION

export const sendEmailAction = {
  request: createActionCreator(
    ESendEmailAction.SEND_EMAIL_REQUEST,
    (resolve) =>
      (
        materials: TSendEmailMaterials,
        successCallback?: (response: TSendEmailResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TSendEmailRequest => {
        return resolve({ materials, successCallback, failedCallback });
      },
  ),
  success: createActionCreator(
    ESendEmailAction.SEND_EMAIL_SUCCESS,
    (resolve) =>
      (response: TSendEmailResponse): TSendEmailSuccess => {
        return resolve({ response });
      },
  ),
  failure: createActionCreator(ESendEmailAction.SEND_EMAIL_FAILED, (resolve) => (error: any): TSendEmailFailed => {
    message.error('SEND_EMAIL_FAILED Action:', error.data.status_message);

    return resolve({ error });
  }),
};
