import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TAddListEmployeeParams = unknown;

export type TAddListEmployeeResponse = TCommonResponse;

export type TAddListEmployeeMaterials = {
  arrayList?: TAddListEmployeeParams;
  company_id?: TAddListEmployeeParams;
};

export const addListEmployee = async ({
  arrayList,
  company_id,
}: TAddListEmployeeMaterials): Promise<TAddListEmployeeResponse> => {
  const params = {
    'params': {
      'list_employees': arrayList,
      'company_id': company_id,
    },
  };
  const response = await ApiService.post('/companies/api/addListEmployees/', params);
  return response?.data; // Return the data from the response to sagas
};
