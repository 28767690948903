import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TPostProjectSettingParams = unknown;

export type TPostProjectSettingMaterials = {
  dataSubmit?: any;
};

export type TPostProjectSettingResponse = TCommonResponse;

export const postProjectSetting = async (
  dataSubmit: TPostProjectSettingMaterials,
): Promise<TPostProjectSettingResponse> => {
  const data = await dataSubmit;
  const response = await ApiService.post(`/projects/api/updateProjectSetting/`, data);
  return response?.data; // Return the data from the response
};
