import { TAuthState } from '@/redux/reducers/auth';
import { TUpdateUserSuccess } from '@/redux/actions/auth';

export const updateUserUpdateState = (state: TAuthState, action: TUpdateUserSuccess): TAuthState => {
  const updatedState = {
    ...state,
    changePasswordResponse: action.payload.response,
  };

  return updatedState;
};
