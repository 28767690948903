import { TAuthState } from '@/redux/reducers/auth';
import { TUpdateProfileSuccess } from '@/redux/actions/auth';

export const updateProfileUpdateState = (state: TAuthState, action: TUpdateProfileSuccess): TAuthState => {
  const updatedState = {
    ...state,
    changePasswordResponse: action.payload.response,
  };

  return updatedState;
};
