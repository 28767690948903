import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';
import axios from 'axios';

// TYPES

export type TCreateProjectParams = unknown;

export type TCreateProjectResponse = TCommonResponse;

export type TCreateProjectMaterials = {
  name?: TCreateProjectParams;
  company_id?: TCreateProjectParams;
};

export const createProject = async ({ name, company_id }: TCreateProjectMaterials): Promise<TCreateProjectResponse> => {
  const params = {
    'params': {
      'name': name?.toString(),
      'company_id': company_id,
    },
  };
  const response = await ApiService.post(`/projects/api/createProject/`, params);
  return response?.data; // Return the data from the response to sagas
};
