import { TCompanyState } from '@/redux/reducers/company';
import { TSendEmailSuccess } from '@/redux/actions/company';

export const sendEmailUpdateState = (state: TCompanyState, action: TSendEmailSuccess): TCompanyState => {
  const updatedState = {
    ...state,
    sendEmailResponse: action.payload.response,
  };

  return updatedState;
};
