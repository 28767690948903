import { TCommonResponse } from '@/common/types';
import ApiService from '@/services/api';
import message from '@/utils/message';

// TYPES

export type TGetListStateParams = unknown;

export type TGetListStateResponse = TCommonResponse;

export type TGetListStateMaterials = {
  country_id?: TGetListStateParams; // Correct the property name to "project_id"
};

export const getListState = async (params): Promise<TGetListStateResponse> => {
  try {
    const response = await ApiService.get(`/companies/api/getListStatesByCountryId/?country_id=${params.country_id}`);
    return response?.data; // Return the data from the response
  } catch (error) {
    message.error('API Error:', error.data.status_message);
  }
};
