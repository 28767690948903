import { createActionCreator } from 'deox';

import { TAssignUsersListMaterials, TAssignUsersListResponse } from '@/services/api/employee';

// CONSTANTS

export enum EAssignUsersListAction {
  ASSIGN_USERS_LIST = 'ASSIGN_USERS_LIST',
  ASSIGN_USERS_LIST_REQUEST = 'ASSIGN_USERS_LIST_REQUEST',
  ASSIGN_USERS_LIST_SUCCESS = 'ASSIGN_USERS_LIST_SUCCESS',
  ASSIGN_USERS_LIST_FAILED = 'ASSIGN_USERS_LIST_FAILED',
}

// TYPES

export type TAssignUsersListRequest = {
  type: EAssignUsersListAction.ASSIGN_USERS_LIST_REQUEST;
  payload: {
    materials: TAssignUsersListMaterials;
    successCallback?: (response: TAssignUsersListResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TAssignUsersListSuccess = {
  type: EAssignUsersListAction.ASSIGN_USERS_LIST_SUCCESS;
  payload: { response: TAssignUsersListResponse };
};

export type TAssignUsersListFailed = { type: EAssignUsersListAction.ASSIGN_USERS_LIST_FAILED };

// FUNCTION

export const assignUsersListAction = {
  request: createActionCreator(
    EAssignUsersListAction.ASSIGN_USERS_LIST_REQUEST,
    (resolve) =>
      (
        materials: TAssignUsersListMaterials,
        successCallback?: (response: TAssignUsersListResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TAssignUsersListRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EAssignUsersListAction.ASSIGN_USERS_LIST_SUCCESS,
    (resolve) =>
      (response: TAssignUsersListResponse): TAssignUsersListSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EAssignUsersListAction.ASSIGN_USERS_LIST_FAILED,
    (resolve) =>
      (error: any): TAssignUsersListFailed =>
        resolve({ error }),
  ),
};
