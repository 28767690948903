import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { getUserInfoAction } from '@/redux/actions/auth';
import { TGetUserInfoResponse, getUserInfo } from '@/services/api/auth';
import message from '@/utils/message';
import { handleAPIError } from '@/utils/handle-api-error';

// FUNCTION

export function* getUserInfoSaga(action: ActionType<typeof getUserInfoAction.request>): Generator {
  const { successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(getUserInfo);
    const getUserInfoResponse: TGetUserInfoResponse = response as TGetUserInfoResponse;
    yield put(getUserInfoAction.success(getUserInfoResponse.data));
    successCallback?.(getUserInfoResponse);
  } catch (err) {
    handleAPIError(err, 'Error.getUserInfoError');
    yield put(getUserInfoAction.failure(err));
    failedCallback?.(err);
  }
}
