import { TAuthState } from '@/redux/reducers/auth';
import { TSendMailSuccess } from '@/redux/actions/auth';

export const sendMailUpdateState = (state: TAuthState, action: TSendMailSuccess): TAuthState => {
  const updatedState = {
    ...state,
    sendMailResponse: action.payload.response,
  };

  return updatedState;
};
