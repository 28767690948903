import React, { useEffect, useRef } from 'react';
import { Redirect, Router } from '@reach/router';
import { useDispatch } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { AuthRoute, LayoutPaths, Pages, Paths, ProtectedRoute, PublicRoute } from '@/pages/routers';
import Guest from '@/layouts/Guest';
import Auth from '@/layouts/Auth';
import Admin from '@/layouts/Admin';
import { uiActions } from '@/redux/actions';
import i18n from '@/i18n/i18n';

const App: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const updateSize = (): void => {
      dispatch(uiActions.setDevice(window.innerWidth));
    };
    window.addEventListener('resize', updateSize);
    return (): void => window.removeEventListener('resize', updateSize);
  }, [dispatch]);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        <Router primary={false}>
          <Guest path={LayoutPaths.Guest}>
            <PublicRoute path={Paths.Login} component={Pages.Login} />
            <ProtectedRoute path={Paths.Project} component={Pages.Project} />
            <ProtectedRoute path={Paths.Account} component={Pages.Account} />
            <Redirect from={Paths.Rest} to={Paths.Login} noThrow />
          </Guest>

          <Auth path={LayoutPaths.Auth}>
            <Redirect from={Paths.Rest} to={`${LayoutPaths.Auth}${Paths.Login}`} noThrow />
          </Auth>
        </Router>
      </div>
    </I18nextProvider>
  );
};

export default App;
