import { TProjectSettingState } from '@/redux/reducers/project-setting';
import { TPostProjectSettingSuccess } from '@/redux/actions/project-setting';

export const postProjectSettingUpdateState = (
  state: TProjectSettingState,
  action: TPostProjectSettingSuccess,
): TProjectSettingState => {
  const updatedState = {
    ...state,
    postProjectSettingResponse: action.payload.response,
  };
  return updatedState;
};
