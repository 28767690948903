import { TEmployeeState } from '@/redux/reducers/employee';
import { TAssignProjectListSuccess } from '@/redux/actions/employee';

export const assignProjectListUpdateState = (
  state: TEmployeeState,
  action: TAssignProjectListSuccess,
): TEmployeeState => {
  const updatedState = {
    ...state,
    assignProjectListResponse: action.payload.response,
  };
  return updatedState;
};
