import { TProjectState } from '@/redux/reducers/project';
import { TCreateProjectSuccess } from '@/redux/actions/project';

export const createProjectUpdateState = (state: TProjectState, action: TCreateProjectSuccess): TProjectState => {
  const updatedState = {
    ...state,
    createProjectResponse: action.payload.response,
  };

  return updatedState;
};
