import { TEmployeeState } from '@/redux/reducers/employee';
import { TUpdateProfileEmployeeSuccess } from '@/redux/actions/employee';

export const updateProfileEmployeeUpdateState = (
  state: TEmployeeState,
  action: TUpdateProfileEmployeeSuccess,
): TEmployeeState => {
  const updatedState = {
    ...state,
    updateProfileEmployeeResponse: action.payload.response,
  };
  return updatedState;
};
